import React, { useState } from 'react';

import {NavBar} from '../common/Header';
import Delegates from './Delegate';
import {Proposals} from './Proposals';
import { ProposalList } from './ProposalList';
import './gov.scss'

const StaticDesc = () =>(
    <div className='row flex-sm-row pt-5 mt-5 text-center d-flex'>
    <div className='col-12 '>
        <div className='d-flex heading-usado justify-content-center align-items-center'>
            <p className='big-font orange-color'>USDAO &nbsp;</p>
            <p className='big-font '> Governance</p>
        </div>
        <p className='caption-font'>
            DAOGOV tokens represent voting shares in USDAO governance. You can
            vote on each proposal yourself or delegate your votes to a third
            party.
        </p>
        <br />
        <a type='button' className='my-3  keyboard-shadow link-read-more' rel="noopener noreferrer" target="_blank" href='https://docs.usdao.io/docs/decentralized-governance/usdao-governance-protocol'>
            Read More About USDAO Governance{' '}
        </a>
    </div>
</div>
)

const Governance = () =>{
	

    const [votes, setVotes] = useState({});

    return(
        <>
				<NavBar />

        <div className='v2-governance' id = 'governance'>

			<div className=' align-items-center justify-content-center'>
                <StaticDesc />
                <Delegates votes = {votes} setVotes = {setVotes}/>
                <Proposals />
                <ProposalList votes = {votes}/>
			</div>

        </div>
        </>
    )
}

export default Governance;