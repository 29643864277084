import React from 'react'
import { Link } from "react-router-dom" 

const LeftNav = () =>{
    return(
        <div className='leftNav'>
        <ul>
            <li>Admin Panel</li>
            <li><Link to="/admin/fee">USDAO Fee</Link></li>
            <li><Link to="/admin/revenue">Revenue</Link></li>

        </ul>
        </div>
    )
}

export default LeftNav;