export const contractDetails = {
	usm: {
		name: 'usm',
		tokenImage: 'https://usdao.io/static/media/stable-coin.88a3d2a14d839faf59fda4d2ec296ad1.svg',
		abi: [
			{
				"inputs": [
					{
						"internalType": "contract Oracle",
						"name": "oracle_",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "addressesYouCantSendThisContractsTokensTo",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "contractsToAskToRejectSendsToThisContractsAddress",
						"type": "address[]"
					},
					{
						"internalType": "address payable",
						"name": "_foundationAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "minWithdrawalSeconds",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "_communityIssuance",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_ASSETSale",
						"type": "address"
					}
				],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Approval",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"name": "BidAskAdjustmentChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "enum USM.FeesTypes",
						"name": "feeType",
						"type": "uint8"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "newFeee",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "FeeChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "newFoundationAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "FoundationAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "saleContractAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "NewAssetLiquidityAdded",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "user",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "bool",
						"name": "newStatus",
						"type": "bool"
					}
				],
				"name": "OptOutStatusChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "newOracleAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "OracleContractsUpgraded",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "oraclePrice",
						"type": "uint256"
					}
				],
				"name": "PriceChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "from",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Transfer",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "bool",
						"name": "underwater",
						"type": "bool"
					}
				],
				"name": "UnderwaterStatusChanged",
				"type": "event"
			},
			{
				"inputs": [],
				"name": "ASSETBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ASSETMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "BID_ASK_ADJUSTMENT_DECAY_PER_SECOND",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "BID_ASK_ADJUSTMENT_ZERO_OUT_PERIOD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "DOMAIN_SEPARATOR",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MAX_DEBT_RATIO",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MINIMUM_DELAY",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MIN_FUM_BUY_PRICE_DECAY_PER_SECOND",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PERMIT_TYPEHASH",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PREFUND_END_TIMESTAMP",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAODebt",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOTransferFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "WAD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "assetSaleContract",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "addAssetLiquidity",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"name": "adjustedEthUsdPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					}
				],
				"name": "allowance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "approve",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "guy",
						"type": "address"
					}
				],
				"name": "balanceOf",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "storedTime",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "storedAdjustment",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentTime",
						"type": "uint256"
					}
				],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address payable",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "usmToBurn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "minEthOut",
						"type": "uint256"
					}
				],
				"name": "burn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newburnFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeBurnFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_newFoundationAddress",
						"type": "address"
					}
				],
				"name": "changeFoundationAddress",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newmintFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeMintFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					}
				],
				"name": "checkForFreshOraclePrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "oraclePrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "usmActualSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethPool_",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "oldTimeUnderwater",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentTime",
						"type": "uint256"
					}
				],
				"name": "checkIfUnderwater",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "timeSystemWentUnderwater_",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupplyForFumBuys",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "debtRatio_",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupply",
						"type": "uint256"
					}
				],
				"name": "debtRatio",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ratio",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "decimals",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_netDebtDecrease",
						"type": "uint256"
					}
				],
				"name": "decreaseUSDAODebt",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupply",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethBuffer",
				"outputs": [
					{
						"internalType": "int256",
						"name": "buffer",
						"type": "int256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "usmIn",
						"type": "uint256"
					}
				],
				"name": "ethFromBurn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ethPool",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "pool",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethToUsm",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "foundationAddress",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "foundationDefund",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "foundationFund",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fum",
				"outputs": [
					{
						"internalType": "contract IFUM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "debtRatio_",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "prefund",
						"type": "bool"
					}
				],
				"name": "fumFromFund",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "fumOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmEffectiveSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "prefund",
						"type": "bool"
					}
				],
				"name": "fumPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fumTotalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "supply",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					}
				],
				"name": "getAssetBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getAssetMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getTransferFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					}
				],
				"name": "getUSDAOBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getUSDAOMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_netDebtIncrease",
						"type": "uint256"
					}
				],
				"name": "increaseUSDAODebt",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "latestPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "loadState",
				"outputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "minUsmOut",
						"type": "uint256"
					}
				],
				"name": "mint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "payable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "name",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "nonces",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultBurn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultETHTransfer",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultMint",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_borrOperation",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_defaultPool",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_deployer",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_stakingPool",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_vaultManager",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optBackIn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optOut",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "optedOut",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "oracle",
				"outputs": [
					{
						"internalType": "contract Oracle",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "deadline",
						"type": "uint256"
					},
					{
						"internalType": "uint8",
						"name": "v",
						"type": "uint8"
					},
					{
						"internalType": "bytes32",
						"name": "r",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "s",
						"type": "bytes32"
					}
				],
				"name": "permit",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_poolAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_receiver",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "returnFromPool",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "revenueContract",
				"outputs": [
					{
						"internalType": "contract Revenue",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_sender",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_poolAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "sendToPool",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_onVault_borrOperation",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_stakingPool",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_vaultManager",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_defaultPool",
						"type": "address"
					}
				],
				"name": "setOnVaultAddress",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "storedState",
				"outputs": [
					{
						"internalType": "uint32",
						"name": "timeSystemWentUnderwater",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "ethUsdPrice",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "oracleEthUsdPrice",
						"type": "uint64"
					},
					{
						"internalType": "uint32",
						"name": "bidAskAdjustmentTimestamp",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "bidAskAdjustment",
						"type": "uint64"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "symbol",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "timeSystemWentUnderwater",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "totalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transfer",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "src",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transferFrom",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "contract Oracle",
						"name": "_newOracle",
						"type": "address"
					}
				],
				"name": "upgradeOracle",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					}
				],
				"name": "usmFromMint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjShrinkFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					}
				],
				"name": "usmPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "usmToEth",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "version",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"stateMutability": "payable",
				"type": "receive"
			}
		],
		address: {
			1: '0x03eb7Ce2907e202bB70BAE3D7B0C588573d3cECC',
			42: '0x6DEE30518efD2d2b32D8878c494309Ee9c0A4489',
			// 42: '0xCAC1daf45199454d982B818DcC8845B8a1AEE2D9',
			31337: '0x8Ad470569f02E93B7381d568615Dc3AB07fEB3Bd',
			1337: '0x8a3b2Bc31ac6B3835E698B3446e089FE86974FB0',
			4: '0x7A45D2E67A055447531793BF05C3CF84485ED650',
			5: '0x2913b9FdAb2BFfC02013C4a3227764c121C15118'
		}
	},
	//asset
	fum: {
		name: 'fum',
		tokenImage: 'https://usdao.io/static/media/asset-token.de367b423f04d72447e01eb24c6e8b90.svg',
		abi: [
			{
				"inputs": [
					{
						"internalType": "address[]",
						"name": "addressesYouCantSendThisContractsTokensTo",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "contractsToAskToRejectSendsToThisContractsAddress",
						"type": "address[]"
					},
					{
						"internalType": "address",
						"name": "_communityIssuance",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_ASSETSale",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_foundationAddress",
						"type": "address"
					}
				],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Approval",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "user",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "bool",
						"name": "newStatus",
						"type": "bool"
					}
				],
				"name": "OptOutStatusChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "from",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Transfer",
				"type": "event"
			},
			{
				"inputs": [],
				"name": "DOMAIN_SEPARATOR",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PERMIT_TYPEHASH",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					}
				],
				"name": "allowance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "approve",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "guy",
						"type": "address"
					}
				],
				"name": "balanceOf",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "holder",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "burn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "decimals",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "recipient",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "mint",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "name",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "nonces",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optBackIn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optOut",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "optedOut",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "deadline",
						"type": "uint256"
					},
					{
						"internalType": "uint8",
						"name": "v",
						"type": "uint8"
					},
					{
						"internalType": "bytes32",
						"name": "r",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "s",
						"type": "bytes32"
					}
				],
				"name": "permit",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "symbol",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "totalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transfer",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "src",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transferFrom",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "usm",
				"outputs": [
					{
						"internalType": "contract IUSM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "version",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			}
		],
		address: {
			1: '0xf04a5D82ff8a801f7d45e9C14CDcf73defF1a394',
			42: '0x0cAe206C124CD82FC79F666402cB7dAe8030b0b6',
			// 42: '0x647Ef2F617dFFD1b1d007Ee4eFc6F62c188C1910',
			31337: '0x267cbD010d7E48421760dc8539898F8f1A866a50',
			1337: '0xbA4523C919633dE2A5590226e6b69dE1a48b2B2C',
			4: '0x139972feb95C15D4E6e0b26CF10Cf44f7a1684Ed',
			5: '0x00c5DBD5Ffa142cd4BCCB648A53328c44810903e'
		},
		infuraId: {
			1: '',
			42: 'https://rinkeby.infura.io/v3/ad3b219cf3254a9ea9815d52438578d5',
			31337: '',
			1337: '',
			4: 'https://rinkeby.infura.io/v3/0c7a63bd7d2d4b7e9d3dc7032ac1bc6e'
		}
	},
	governorAlpha: {
		name: 'governorAlpha',
		abi: [
			{
				"inputs": [
					{
						"internalType": "contract Oracle",
						"name": "oracle_",
						"type": "address"
					},
					{
						"internalType": "address[]",
						"name": "addressesYouCantSendThisContractsTokensTo",
						"type": "address[]"
					},
					{
						"internalType": "address[]",
						"name": "contractsToAskToRejectSendsToThisContractsAddress",
						"type": "address[]"
					},
					{
						"internalType": "address",
						"name": "_timelockAddress",
						"type": "address"
					},
					{
						"internalType": "address payable",
						"name": "_foundationAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "minWithdrawalSeconds",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "_communityIssuance",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_ASSETSale",
						"type": "address"
					}
				],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Approval",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"name": "BidAskAdjustmentChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "enum USM.FeesTypes",
						"name": "feeType",
						"type": "uint8"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "newFeee",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "FeeChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "newFoundationAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "FoundationAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "saleContractAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "NewAssetLiquidityAdded",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "user",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "bool",
						"name": "newStatus",
						"type": "bool"
					}
				],
				"name": "OptOutStatusChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "newOracleAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "OracleContractsUpgraded",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "oraclePrice",
						"type": "uint256"
					}
				],
				"name": "PriceChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "from",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Transfer",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "bool",
						"name": "underwater",
						"type": "bool"
					}
				],
				"name": "UnderwaterStatusChanged",
				"type": "event"
			},
			{
				"inputs": [],
				"name": "ASSETBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ASSETMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "BID_ASK_ADJUSTMENT_DECAY_PER_SECOND",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "BID_ASK_ADJUSTMENT_ZERO_OUT_PERIOD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "DOMAIN_SEPARATOR",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MAX_DEBT_RATIO",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MINIMUM_DELAY",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MIN_FUM_BUY_PRICE_DECAY_PER_SECOND",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PERMIT_TYPEHASH",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PREFUND_END_TIMESTAMP",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAODebt",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOTransferFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "WAD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "assetSaleContract",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "addAssetLiquidity",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"name": "adjustedEthUsdPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					}
				],
				"name": "allowance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "approve",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "guy",
						"type": "address"
					}
				],
				"name": "balanceOf",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "storedTime",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "storedAdjustment",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentTime",
						"type": "uint256"
					}
				],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address payable",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "usmToBurn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "minEthOut",
						"type": "uint256"
					}
				],
				"name": "burn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newburnFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeBurnFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_newFoundationAddress",
						"type": "address"
					}
				],
				"name": "changeFoundationAddress",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newmintFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeMintFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newtransferFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeTransferFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					}
				],
				"name": "checkForFreshOraclePrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "oraclePrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "usmActualSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethPool_",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "oldTimeUnderwater",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentTime",
						"type": "uint256"
					}
				],
				"name": "checkIfUnderwater",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "timeSystemWentUnderwater_",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupplyForFumBuys",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "debtRatio_",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupply",
						"type": "uint256"
					}
				],
				"name": "debtRatio",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ratio",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "decimals",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_netDebtDecrease",
						"type": "uint256"
					}
				],
				"name": "decreaseUSDAODebt",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupply",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethBuffer",
				"outputs": [
					{
						"internalType": "int256",
						"name": "buffer",
						"type": "int256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "usmIn",
						"type": "uint256"
					}
				],
				"name": "ethFromBurn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ethPool",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "pool",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethToUsm",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "foundationAddress",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "foundationDefund",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "foundationFund",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fum",
				"outputs": [
					{
						"internalType": "contract IFUM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "debtRatio_",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "prefund",
						"type": "bool"
					}
				],
				"name": "fumFromFund",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "fumOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmEffectiveSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "prefund",
						"type": "bool"
					}
				],
				"name": "fumPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fumTotalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "supply",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					}
				],
				"name": "getAssetBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getAssetMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getTransferFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					}
				],
				"name": "getUSDAOBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getUSDAOMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_netDebtIncrease",
						"type": "uint256"
					}
				],
				"name": "increaseUSDAODebt",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "latestPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "loadState",
				"outputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "minUsmOut",
						"type": "uint256"
					}
				],
				"name": "mint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "payable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "name",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "nonces",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultBurn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultETHTransfer",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultMint",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_borrOperation",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_defaultPool",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_deployer",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_stabilityPool",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_vaultManager",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optBackIn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optOut",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "optedOut",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "oracle",
				"outputs": [
					{
						"internalType": "contract Oracle",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "deadline",
						"type": "uint256"
					},
					{
						"internalType": "uint8",
						"name": "v",
						"type": "uint8"
					},
					{
						"internalType": "bytes32",
						"name": "r",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "s",
						"type": "bytes32"
					}
				],
				"name": "permit",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_poolAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_receiver",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "returnFromPool",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "revenueContract",
				"outputs": [
					{
						"internalType": "contract Revenue",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_sender",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_poolAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "sendToPool",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_onVault_borrOperation",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_stabilityPool",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_vaultManager",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_defaultPool",
						"type": "address"
					}
				],
				"name": "setOnVaultAddress",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "storedState",
				"outputs": [
					{
						"internalType": "uint32",
						"name": "timeSystemWentUnderwater",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "ethUsdPrice",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "oracleEthUsdPrice",
						"type": "uint64"
					},
					{
						"internalType": "uint32",
						"name": "bidAskAdjustmentTimestamp",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "bidAskAdjustment",
						"type": "uint64"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "symbol",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "timeSystemWentUnderwater",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "timelockAddress",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "totalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transfer",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "src",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transferFrom",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "contract Oracle",
						"name": "_newOracle",
						"type": "address"
					}
				],
				"name": "upgradeOracle",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					}
				],
				"name": "usmFromMint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjShrinkFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					}
				],
				"name": "usmPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "usmToEth",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "version",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"stateMutability": "payable",
				"type": "receive"
			}
		],
		address: {
			1: '0x03eb7Ce2907e202bB70BAE3D7B0C588573d3cECC',
			42: '0x6DEE30518efD2d2b32D8878c494309Ee9c0A4489',
			// 42: '0xCAC1daf45199454d982B818DcC8845B8a1AEE2D9',
			31337: '0x8Ad470569f02E93B7381d568615Dc3AB07fEB3Bd',
			1337: '0x8a3b2Bc31ac6B3835E698B3446e089FE86974FB0',
			4: '0xf69aAF6F832ddb1e368BA200d591274c829D3336',
			5: ''
		}
	},
	quiz: {
		name: 'quiz',
		abi: [
			{
				inputs: [{ internalType: 'address', name: '_p', type: 'address' }],
				name: 'registerParticipant',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'bytes32', name: '_qs', type: 'bytes32' },
					{ internalType: 'bytes32', name: '_userOpt', type: 'bytes32' },
					{ internalType: 'uint256', name: 'amount', type: 'uint256' }
				],
				name: 'userResponse',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'bytes32[]', name: '_qs', type: 'bytes32[]' },
					{ internalType: 'bytes32[]', name: '_userOpt', type: 'bytes32[]' },
					{ internalType: 'uint256[]', name: 'amount', type: 'uint256[]' }
				],
				name: 'userResponseArray',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'address', name: '_usdao', type: 'address' },
					{ internalType: 'bytes32[]', name: '_qs', type: 'bytes32[]' },
					{ internalType: 'bytes32[]', name: '_ans', type: 'bytes32[]' }
				],
				stateMutability: 'nonpayable',
				type: 'constructor'
			},
			{
				inputs: [],
				name: 'admin',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
				name: 'isRegistered',
				outputs: [
					{ internalType: 'bool', name: 'isUserRegistered', type: 'bool' }
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				name: 'participants',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'address', name: '', type: 'address' },
					{ internalType: 'bytes32', name: '', type: 'bytes32' }
				],
				name: 'responses',
				outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'totalParticipants',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'usdao',
				outputs: [
					{ internalType: 'contract IERC20', name: '', type: 'address' }
				],
				stateMutability: 'view',
				type: 'function'
			}
		],
		address: {
			1: '0x03eb7Ce2907e202bB70BAE3D7B0C588573d3cECC',
			42: '0x6DEE30518efD2d2b32D8878c494309Ee9c0A4489',
			// 42: '0xCAC1daf45199454d982B818DcC8845B8a1AEE2D9',
			31337: '0x8Ad470569f02E93B7381d568615Dc3AB07fEB3Bd',
			1337: '0x8a3b2Bc31ac6B3835E698B3446e089FE86974FB0',
			4: '0xcE8285F6D8e55BFCaA01E030695DBeFC81d26F0A',
			5: ''
		}
	},
	usmView: {
		name: 'USMView',
		abi: [
			{
				"inputs": [
					{
						"internalType": "contract IUSM",
						"name": "usm_",
						"type": "address"
					}
				],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"inputs": [],
				"name": "WAD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "debtRatio",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ratio",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethBuffer",
				"outputs": [
					{
						"internalType": "int256",
						"name": "buffer",
						"type": "int256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethToUsm",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "usm",
				"outputs": [
					{
						"internalType": "contract IUSM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "usmToBurn",
						"type": "uint256"
					}
				],
				"name": "usmBurn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					}
				],
				"name": "usmMint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjShrinkFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					}
				],
				"name": "usmPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "usmAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "usmToEth",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			}
		],

		address: {
			1: '',
			42: '0xa9F8eC6a621aD5A53f7281F80bfc1965cD95ed2f',
			// 42: '0x684592d53ae1DB855E20e10e95dC95f9F172f9d0',
			31337: '0x17219EB39ff85de43F397d030E2D687Da9e94768',
			1337: '0x725D5eA7eF06093e7a548D7Ab1C81C1a82Ee2079',
			4: '0x3b88E560963a09F406c17c3694229309383042E3',
			5: '0xa742caBd331b0fD831ab77E51f82cC49a6e48F22'
		}
	},
	governorAlpha: {
		name: 'governorAlpha',
		abi: [
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Approval",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"name": "BidAskAdjustmentChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "enum USM.FeesTypes",
						"name": "feeType",
						"type": "uint8"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "newFeee",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "FeeChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "newFoundationAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "FoundationAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "saleContractAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "NewAssetLiquidityAdded",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "user",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "bool",
						"name": "newStatus",
						"type": "bool"
					}
				],
				"name": "OptOutStatusChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "newOracleAddress",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "updateTime",
						"type": "uint256"
					}
				],
				"name": "OracleContractsUpgraded",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "oraclePrice",
						"type": "uint256"
					}
				],
				"name": "PriceChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "from",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Transfer",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "bool",
						"name": "underwater",
						"type": "bool"
					}
				],
				"name": "UnderwaterStatusChanged",
				"type": "event"
			},
			{
				"inputs": [],
				"name": "ASSETBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ASSETMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "BID_ASK_ADJUSTMENT_DECAY_PER_SECOND",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "BID_ASK_ADJUSTMENT_ZERO_OUT_PERIOD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "DOMAIN_SEPARATOR",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MAX_DEBT_RATIO",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MINIMUM_DELAY",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MIN_FUM_BUY_PRICE_DECAY_PER_SECOND",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PERMIT_TYPEHASH",
				"outputs": [
					{
						"internalType": "bytes32",
						"name": "",
						"type": "bytes32"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PREFUND_END_TIMESTAMP",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAODebt",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAOTransferFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "WAD",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "assetSaleContract",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "addAssetLiquidity",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"name": "adjustedEthUsdPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					}
				],
				"name": "allowance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "approve",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "guy",
						"type": "address"
					}
				],
				"name": "balanceOf",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "storedTime",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "storedAdjustment",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentTime",
						"type": "uint256"
					}
				],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "bidAskAdjustment",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address payable",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "usmToBurn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "minEthOut",
						"type": "uint256"
					}
				],
				"name": "burn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newburnFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeBurnFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newDefundFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeDefundFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_newFoundationAddress",
						"type": "address"
					}
				],
				"name": "changeFoundationAddress",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newFundFee",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeFundFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newmintFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeMintFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint8",
						"name": "_newtransferFees",
						"type": "uint8"
					},
					{
						"internalType": "uint32",
						"name": "_base",
						"type": "uint32"
					}
				],
				"name": "changeTransferFee",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					}
				],
				"name": "checkForFreshOraclePrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "oraclePrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjustment",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "usmActualSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethPool_",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "oldTimeUnderwater",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "currentTime",
						"type": "uint256"
					}
				],
				"name": "checkIfUnderwater",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "timeSystemWentUnderwater_",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupplyForFumBuys",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "debtRatio_",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupply",
						"type": "uint256"
					}
				],
				"name": "debtRatio",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ratio",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "decimals",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_netDebtDecrease",
						"type": "uint256"
					}
				],
				"name": "decreaseUSDAODebt",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address payable",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "fumToBurn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "minEthOut",
						"type": "uint256"
					}
				],
				stateMutability: 'pure',
				type: 'function'
			}
		],
		address: {
			1: '',
			42: '0xbffC38542ad545211c28213A4cEE3598f227DC3d',
			// 42: '0x98cDa1b9C670f4530F577508b4139fA31e1BcFF0',
			31337: '',
			1337: '0xc0BFF8A9140EeB7941Fb7C9346679D938343Cb41',
			4: '0x5414fac945bF9653BD1f58A3d89b0A49270a4428',
			5: ''
		}
	},
	//DaoGov
	comp: {
		name: 'gov',
		tokenImage: 'https://usdao.io/static/media/dao-token.d0ed415d82a524b07f89458e2ce8ecee.svg',
		abi: [
			{
				inputs: [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "from",
						"type": "address"
					},
					{
						"internalType": "address payable",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "fumToBurn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "minEthOut",
						"type": "uint256"
					}
				],
				"name": "defundFrom",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmSupply",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethBuffer",
				"outputs": [
					{
						"internalType": "int256",
						"name": "buffer",
						"type": "int256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "usmIn",
						"type": "uint256"
					}
				],
				"name": "ethFromBurn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "fumIn",
						"type": "uint256"
					}
				],
				"name": "ethFromDefund",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjShrinkFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ethPool",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "pool",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "ethToUsm",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "foundationAddress",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fum",
				"outputs": [
					{
						"internalType": "contract IFUM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "debtRatio_",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "prefund",
						"type": "bool"
					}
				],
				"name": "fumFromFund",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "fumOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjGrowthFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "ethInPool",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmEffectiveSupply",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "fumSupply",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "prefund",
						"type": "bool"
					}
				],
				"name": "fumPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fumTotalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "supply",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "minFumOut",
						"type": "uint256"
					}
				],
				"name": "fund",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "fumOut",
						"type": "uint256"
					}
				],
				"stateMutability": "payable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					}
				],
				"name": "getAssetBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getAssetMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getTransferFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethAmount",
						"type": "uint256"
					}
				],
				"name": "getUSDAOBurnFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenAmount",
						"type": "uint256"
					}
				],
				"name": "getUSDAOMintFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_netDebtIncrease",
						"type": "uint256"
					}
				],
				"name": "increaseUSDAODebt",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "isDuringPrefund",
				"outputs": [
					{
						"internalType": "bool",
						"name": "duringPrefund",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "latestPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "loadState",
				"outputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "minUsmOut",
						"type": "uint256"
					}
				],
				"name": "mint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					}
				],
				"stateMutability": "payable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "name",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "nonces",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultBurn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultETHTransfer",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultFund",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "onVaultMint",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_borrOperation",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_defaultPool",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_stabilityPool",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "onVault_troveManager",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optBackIn",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "optOut",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "optedOut",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "oracle",
				"outputs": [
					{
						"internalType": "contract Oracle",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "deadline",
						"type": "uint256"
					},
					{
						"internalType": "uint8",
						"name": "v",
						"type": "uint8"
					},
					{
						"internalType": "bytes32",
						"name": "r",
						"type": "bytes32"
					},
					{
						"internalType": "bytes32",
						"name": "s",
						"type": "bytes32"
					}
				],
				"name": "permit",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_poolAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_receiver",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "returnFromPool",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "revenueContract",
				"outputs": [
					{
						"internalType": "contract Revenue",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_sender",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_poolAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "sendToPool",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_onVault_borrOperation",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_stabilityPool",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_troveManager",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_onVault_defaultPool",
						"type": "address"
					}
				],
				"name": "setOnVaultAddress",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "storedState",
				"outputs": [
					{
						"internalType": "uint32",
						"name": "timeSystemWentUnderwater",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "ethUsdPrice",
						"type": "uint64"
					},
					{
						"internalType": "uint64",
						"name": "oracleEthUsdPrice",
						"type": "uint64"
					},
					{
						"internalType": "uint32",
						"name": "bidAskAdjustmentTimestamp",
						"type": "uint32"
					},
					{
						"internalType": "uint64",
						"name": "bidAskAdjustment",
						"type": "uint64"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "symbol",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "timeSystemWentUnderwater",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "timestamp",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "timelockAddress",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "totalSupply",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transfer",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "src",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "dst",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "wad",
						"type": "uint256"
					}
				],
				"name": "transferFrom",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "contract Oracle",
						"name": "_newOracle",
						"type": "address"
					}
				],
				"name": "upgradeOracle",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "timeSystemWentUnderwater",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "oracleEthUsdPrice",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustmentTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "bidAskAdjustment",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "ethPool",
								"type": "uint256"
							},
							{
								"internalType": "uint256",
								"name": "usmTotalSupply",
								"type": "uint256"
							}
						],
						"internalType": "struct IUSM.LoadedState",
						"name": "ls",
						"type": "tuple"
					},
					{
						"internalType": "uint256",
						"name": "ethIn",
						"type": "uint256"
					}
				],
				"name": "usmFromMint",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "usmOut",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "adjShrinkFactor",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "enum IUSM.Side",
						"name": "side",
						"type": "uint8"
					},
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					}
				],
				"name": "usmPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "price",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "ethUsdPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "usmAmount",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "roundUp",
						"type": "bool"
					}
				],
				"name": "usmToEth",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "ethOut",
						"type": "uint256"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "version",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "pure",
				"type": "function"
			},
			{
				"stateMutability": "payable",
				"type": "receive"
			}
		],
		address: {
			1: '0x03eb7Ce2907e202bB70BAE3D7B0C588573d3cECC',
			42: '0x6DEE30518efD2d2b32D8878c494309Ee9c0A4489',
			// 42: '0xCAC1daf45199454d982B818DcC8845B8a1AEE2D9',
			31337: '0x8Ad470569f02E93B7381d568615Dc3AB07fEB3Bd',
			1337: '0x8a3b2Bc31ac6B3835E698B3446e089FE86974FB0',
			4: '0xf69aAF6F832ddb1e368BA200d591274c829D3336',
			5: ''
		}
	},
	quiz: {
		name: 'quiz',
		abi: [
			{
				inputs: [{ internalType: 'address', name: '_p', type: 'address' }],
				name: 'registerParticipant',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'bytes32', name: '_qs', type: 'bytes32' },
					{ internalType: 'bytes32', name: '_userOpt', type: 'bytes32' },
					{ internalType: 'uint256', name: 'amount', type: 'uint256' }
				],
				name: 'userResponse',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'bytes32[]', name: '_qs', type: 'bytes32[]' },
					{ internalType: 'bytes32[]', name: '_userOpt', type: 'bytes32[]' },
					{ internalType: 'uint256[]', name: 'amount', type: 'uint256[]' }
				],
				name: 'userResponseArray',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'address', name: '_usdao', type: 'address' },
					{ internalType: 'bytes32[]', name: '_qs', type: 'bytes32[]' },
					{ internalType: 'bytes32[]', name: '_ans', type: 'bytes32[]' }
				],
				stateMutability: 'nonpayable',
				type: 'constructor'
			},
			{
				inputs: [],
				name: 'admin',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
				name: 'isRegistered',
				outputs: [
					{ internalType: 'bool', name: 'isUserRegistered', type: 'bool' }
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				name: 'participants',
				outputs: [{ internalType: 'address', name: '', type: 'address' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [
					{ internalType: 'address', name: '', type: 'address' },
					{ internalType: 'bytes32', name: '', type: 'bytes32' }
				],
				name: 'responses',
				outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'totalParticipants',
				outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'usdao',
				outputs: [
					{ internalType: 'contract IERC20', name: '', type: 'address' }
				],
				stateMutability: 'view',
				type: 'function'
			}
		],
		address: {
			1: '0x03eb7Ce2907e202bB70BAE3D7B0C588573d3cECC',
			42: '0x6DEE30518efD2d2b32D8878c494309Ee9c0A4489',
			// 42: '0xCAC1daf45199454d982B818DcC8845B8a1AEE2D9',
			31337: '0x8Ad470569f02E93B7381d568615Dc3AB07fEB3Bd',
			1337: '0x8a3b2Bc31ac6B3835E698B3446e089FE86974FB0',
			4: '0xcE8285F6D8e55BFCaA01E030695DBeFC81d26F0A',
			5: ''
		}
	},
	tokenSale: {
		name: 'tokenSale',
		abi: [
			{
				"inputs": [
					{
						"internalType": "contract ICOMP",
						"name": "comp_",
						"type": "address"
					},
					{
						"internalType": "contract IUSM",
						"name": "usm_",
						"type": "address"
					},
					{
						"internalType": "contract IFUM",
						"name": "_fum",
						"type": "address"
					}
				],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "spender",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Approval",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "previousOwner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "OwnershipTransferred",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "from",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "value",
						"type": "uint256"
					}
				],
				"name": "Transfer",
				"type": "event"
			},
			{
				"stateMutability": "payable",
				"type": "fallback"
			},
			{
				"inputs": [],
				"name": "EthPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "Stop_ICO",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "_price_tokn",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "allowed",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "comp",
				"outputs": [
					{
						"internalType": "contract ICOMP",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "currentTotalTokens",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "end_ICO",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ethPrice",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "foundationAddress",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fum",
				"outputs": [
					{
						"internalType": "contract IFUM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "fundUSDAOPool",
				"outputs": [
					{
						"internalType": "bool",
						"name": "success",
						"type": "bool"
					}
				],
				"stateMutability": "payable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getASSETBalance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "user",
						"type": "address"
					}
				],
				"name": "getDAOGOVBalance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getEthBalance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ico_enddate",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "ico_startdate",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "locked",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "no_of_tokens",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "owner",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "renounceOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "stage",
				"outputs": [
					{
						"internalType": "enum TokenSale.Stages",
						"name": "",
						"type": "uint8"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "stages",
				"outputs": [
					{
						"internalType": "enum TokenSale.Stages",
						"name": "",
						"type": "uint8"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "tokenPrice",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "_termSale",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "endsIn",
						"type": "uint256"
					}
				],
				"name": "start_ICO",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "termSale",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "termSaleBal",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "totalTokensAvailabe",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "to",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"name": "transferAsset",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "transferOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "unlocked",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "usm",
				"outputs": [
					{
						"internalType": "contract IUSM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					}
				],
				"name": "withdrawAllTestAsset",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_to",
						"type": "address"
					}
				],
				"name": "withdrawAllTestEth",
				"outputs": [
					{
						"internalType": "bool",
						"name": "",
						"type": "bool"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"stateMutability": "payable",
				"type": "receive"
			}
		],
		address: {
			1: '',
			42: '0xFE08F3b4dfd3B921A34c275B3A2530B3FfD2Ccb6',
			// 42: '0xDF54967fBfD9fB6F0B14AdB7d3b86D5454c92C04',
			31337: '',
			1337: '0x9d37F9C0Bc044B49F0f36Ab6C477d28543741173',
			4: '0x6Fd31a85A07D16573ddCa8e0676a6A4E38d9F870',
			5: ''
		}
	},
	stake: {
		name: 'stake',
		abi: [
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "usmContract",
						"type": "address"
					}
				],
				"stateMutability": "nonpayable",
				"type": "constructor"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_nextMinute",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_status",
						"type": "uint256"
					}
				],
				"name": "Claim",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "holder",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "StakeId",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_value",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_startTime",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_time",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_reward",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "bool",
						"name": "_stakingtype",
						"type": "bool"
					}
				],
				"name": "Lock",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"name": "Received",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "Unlock",
				"type": "event"
			},
			{
				"inputs": [],
				"name": "BufferPoolBalance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "TVL",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_calculateReward",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					},
					{
						"internalType": "bool",
						"name": "_sType",
						"type": "bool"
					}
				],
				"name": "_calculateRewardF",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_calculateRewardauto",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_getLockAmount",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_getLockEndValidity",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_getLockStartValidity",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_getLockValidity",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "startvalidity",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "endValidity",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "_getLockedData",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "startvalidity",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "endValidity",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "amount",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "_reward",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "accumulatedFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "accumulatedStakingFee",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "owner",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "spender",
						"type": "address"
					}
				],
				"name": "allowance",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "balances",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "bufferPoolContract",
				"outputs": [
					{
						"internalType": "contract BufferPool",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "bufferTransfer",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_stakId",
						"type": "uint256"
					}
				],
				"name": "calculateReward",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "lockAmount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "noOfDays",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalReward",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalclaimedAmount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "plateformFee",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "totalwithdwableAmount",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "_startVal",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "_endVal",
						"type": "uint256"
					}
				],
				"name": "calrwd",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				stateMutability: 'nonpayable',
				type: 'function'
			}
		],
		address: {
			1: '',
			42: '0x71AD2957E64fa4A984e896C0C313748214f4781D',
			// 42: '0xDF54967fBfD9fB6F0B14AdB7d3b86D5454c92C04',
			31337: '',
			1337: '0x9d37F9C0Bc044B49F0f36Ab6C477d28543741173',
			4: '0x7075d377AC9f43B8a854d2A449BBAFaAE6Be2F22',
			5: '0xCa722a8F5be5b3ABFD28dD654b0EB530Fd946fBB'
		}
	},
	oracle: {
		name: 'oracle',
		abi: [
			{
				inputs: [
					{
						internalType: 'contract AggregatorV3Interface',
						name: 'aggregator_',
						type: 'address'
					}
				],
				stateMutability: 'nonpayable',
				type: 'constructor'
			},
			{
				inputs: [],
				name: 'CHAINLINK_SCALE_FACTOR',
				outputs: [
					{
						internalType: 'uint256',
						name: '',
						type: 'uint256'
					}
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'chainlinkAggregator',
				outputs: [
					{
						internalType: 'contract AggregatorV3Interface',
						name: '',
						type: 'address'
					}
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'latestPrice',
				outputs: [
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'updateTime',
						type: 'uint256'
					}
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'refreshPrice',
				outputs: [
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'updateTime',
						type: 'uint256'
					}
				],
				stateMutability: 'nonpayable',
				type: 'function'
			},
			{
				inputs: [],
				name: 'savedPrice',
				outputs: [
					{
						internalType: 'uint256',
						name: '',
						type: 'uint256'
					}
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [],
				name: 'savedUpdateTime',
				outputs: [
					{
						internalType: 'uint256',
						name: '',
						type: 'uint256'
					}
				],
				stateMutability: 'view',
				type: 'function'
			},
			{
				inputs: [
					{
						internalType: 'uint256',
						name: 'p',
						type: 'uint256'
					}
				],
				name: 'setPrice',
				outputs: [],
				stateMutability: 'nonpayable',
				type: 'function'
			}
		],
		address: {
			1: '',
			42: '0x9813C7032133D3730CE885e8c55eC4E6f0Af9e7c',
			// 42: '0xd395D4267806A6C9db8Ea43a6232Ee93121A1164',
			31337: '0x8893Ea797dbC00a6044430623Cb06b3474320861',
			1337: '0x34eAeF91d6a31fDC9aAeC1d58f77a32A56f471f6',
			4: '0x2fBa56283F46cD3C341848B2fF7fa153E9078b1A',
			5: '0x192de55596C57f059793FD907F2682959e93cb12'
		}
	},
	revenue: {
		name: "revenue",
		abi: [
		  {
			inputs: [
			  {
				internalType: "addresspayable",
				name: "_foundationAddress",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_minWithdrawlSeconds",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "constructor",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "asset",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "time",
				type: "uint256",
			  },
			],
			name: "CommunityAssetWithdraw",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "etherAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "time",
				type: "uint256",
			  },
			],
			name: "CommunityEtherWithdraw",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "usdao",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "time",
				type: "uint256",
			  },
			],
			name: "CommunityUSDAOWithdraw",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "newLimit",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "updateTime",
				type: "uint256",
			  },
			],
			name: "FoundationWidthdrawalLimtChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "usdao",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "time",
				type: "uint256",
			  },
			],
			name: "FoundationWithdraw",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			name: "Received",
			type: "event",
		  },
		  {
			inputs: [],
			name: "WAD",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  { internalType: "address", name: "receiver", type: "address" },
			  { internalType: "address", name: "assetContract", type: "address" },
			  { internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "assetReserveTransfer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  { internalType: "uint8", name: "_newLimt", type: "uint8" },
			  { internalType: "uint32", name: "_base", type: "uint32" },
			],
			name: "changeFoundationWidthdrawalLimt",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  { internalType: "address", name: "receiver", type: "address" },
			  { internalType: "uint256", name: "amount", type: "uint256" },
			],
			name: "etherReserveTransfer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "foundationAddress",
			outputs: [
			  { internalType: "addresspayable", name: "", type: "address" },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "foundationRevenueWithdraw",
			outputs: [{ internalType: "bool", name: "", type: "bool" }],
			stateMutability: "payable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "foundationWithdrawalLimit",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [{ internalType: "address", name: "_fum", type: "address" }],
			name: "getAssetReserve",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getEthBalance",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getUSDAOReserve",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "lastWithdrawalTime",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "minWithdrawlSeconds",
			outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  { internalType: "addresspayable", name: "receiver", type: "address" },
			  { internalType: "uint256", name: "usdaoAmount", type: "uint256" },
			],
			name: "usdaoReserveTransfer",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "usm",
			outputs: [{ internalType: "contractIUSM", name: "", type: "address" }],
			stateMutability: "view",
			type: "function",
		  },
		  { stateMutability: "payable", type: "receive" },
		],
		address: {
		  1: "",
		  42: "0x9813C7032133D3730CE885e8c55eC4E6f0Af9e7c",
		  // 42: '0xd395D4267806A6C9db8Ea43a6232Ee93121A1164',
		  31337: "0x8893Ea797dbC00a6044430623Cb06b3474320861",
		  1337: "0x34eAeF91d6a31fDC9aAeC1d58f77a32A56f471f6",
		  4: "0x3872F4dB03F480Bb694017E4bC14CbE5416e0454",
		  5: "0xC624ff9Bacc564752534201093d903CEA8400cEa",
		},
	  },
	stakingPool: {
		name: 'stakingPool',
		abi: [
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_account",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_feeAmount",
						"type": "uint256"
					}
				],
				"name": "ASSETFeeCharged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "_newBorrowerOperationsAddress",
						"type": "address"
					}
				],
				"name": "BorrowerOperationsAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "_newCommunityIssuanceAddress",
						"type": "address"
					}
				],
				"name": "CommunityIssuanceAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "_newDefaultPoolAddress",
						"type": "address"
					}
				],
				"name": "DefaultPoolAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_P",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_S",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_G",
						"type": "uint256"
					}
				],
				"name": "DepositSnapshotUpdated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_account",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "DistributeReward",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_ETH",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_USDAOLoss",
						"type": "uint256"
					}
				],
				"name": "ETHGainWithdrawn",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint128",
						"name": "_currentEpoch",
						"type": "uint128"
					}
				],
				"name": "EpochUpdated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "_to",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "EtherSent",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_frontEnd",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_kickbackRate",
						"type": "uint256"
					}
				],
				"name": "FrontEndRegistered",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_frontEnd",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_P",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_G",
						"type": "uint256"
					}
				],
				"name": "FrontEndSnapshotUpdated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_frontEnd",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_newFrontEndStake",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					}
				],
				"name": "FrontEndStakeChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "_frontEnd",
						"type": "address"
					}
				],
				"name": "FrontEndTagSet",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_G",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint128",
						"name": "_epoch",
						"type": "uint128"
					},
					{
						"indexed": false,
						"internalType": "uint128",
						"name": "_scale",
						"type": "uint128"
					}
				],
				"name": "G_Updated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "previousOwner",
						"type": "address"
					},
					{
						"indexed": true,
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "OwnershipTransferred",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_P",
						"type": "uint256"
					}
				],
				"name": "P_Updated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_S",
						"type": "uint256"
					},
					{
						"indexed": false,
						"internalType": "uint128",
						"name": "_epoch",
						"type": "uint128"
					},
					{
						"indexed": false,
						"internalType": "uint128",
						"name": "_scale",
						"type": "uint128"
					}
				],
				"name": "S_Updated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint128",
						"name": "_currentScale",
						"type": "uint128"
					}
				],
				"name": "ScaleUpdated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "_newSortedVaultsAddress",
						"type": "address"
					}
				],
				"name": "SortedVaultsAddressChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_newBalance",
						"type": "uint256"
					}
				],
				"name": "StakingPoolETHBalanceUpdated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_newBalance",
						"type": "uint256"
					}
				],
				"name": "StakingPoolUSDAOBalanceUpdated",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": true,
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					},
					{
						"indexed": false,
						"internalType": "uint256",
						"name": "_newDeposit",
						"type": "uint256"
					}
				],
				"name": "UserDepositChanged",
				"type": "event"
			},
			{
				"anonymous": false,
				"inputs": [
					{
						"indexed": false,
						"internalType": "address",
						"name": "_newVaultManagerAddress",
						"type": "address"
					}
				],
				"name": "VaultManagerAddressChanged",
				"type": "event"
			},
			{
				"inputs": [],
				"name": "BORROWING_FEE_FLOOR",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "CCR",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "DECIMAL_PRECISION",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "MCR",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "NAME",
				"outputs": [
					{
						"internalType": "string",
						"name": "",
						"type": "string"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "P",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "PERCENT_DIVISOR",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "SCALE_FACTOR",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "USDAO_GAS_COMPENSATION",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "_100pct",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "borrowerOperations",
				"outputs": [
					{
						"internalType": "contract IBorrowerOperations",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "communityIssuance",
				"outputs": [
					{
						"internalType": "contract ICommunityIssuance",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "currentEpoch",
				"outputs": [
					{
						"internalType": "uint128",
						"name": "",
						"type": "uint128"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "currentScale",
				"outputs": [
					{
						"internalType": "uint128",
						"name": "",
						"type": "uint128"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "defaultPool",
				"outputs": [
					{
						"internalType": "contract IDefaultPool",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "depositSnapshots",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "S",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "P",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "G",
						"type": "uint256"
					},
					{
						"internalType": "uint128",
						"name": "scale",
						"type": "uint128"
					},
					{
						"internalType": "uint128",
						"name": "epoch",
						"type": "uint128"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"name": "deposits",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint128",
						"name": "",
						"type": "uint128"
					},
					{
						"internalType": "uint128",
						"name": "",
						"type": "uint128"
					}
				],
				"name": "epochToScaleToG",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint128",
						"name": "",
						"type": "uint128"
					},
					{
						"internalType": "uint128",
						"name": "",
						"type": "uint128"
					}
				],
				"name": "epochToScaleToSum",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					}
				],
				"name": "getCompoundedUSDAODeposit",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					}
				],
				"name": "getDepositorASSETGain",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_depositor",
						"type": "address"
					}
				],
				"name": "getDepositorETHGain",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getETH",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getEntireSystemColl",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "entireSystemColl",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getEntireSystemDebt",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "entireSystemDebt",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "getTotalUSDAODeposits",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "lastASSETError",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "lastETHError_Offset",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "lastUSDAOLossError_Offset",
				"outputs": [
					{
						"internalType": "uint256",
						"name": "",
						"type": "uint256"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_debtToOffset",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "_collToAdd",
						"type": "uint256"
					}
				],
				"name": "offset",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "owner",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "redeemUSDAO",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "renounceOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "revenueContract",
				"outputs": [
					{
						"internalType": "address",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_borrowerOperationsAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_vaultManagerAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_defaultPoolAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_usdaoTokenAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_sortedVaultsAddress",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_communityIssuanceAddress",
						"type": "address"
					}
				],
				"name": "setAddresses",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "sortedVaults",
				"outputs": [
					{
						"internalType": "contract ISortedVaults",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "uint256",
						"name": "_amount",
						"type": "uint256"
					}
				],
				"name": "stakeUSDAO",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "newOwner",
						"type": "address"
					}
				],
				"name": "transferOwnership",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "usm",
				"outputs": [
					{
						"internalType": "contract IUSM",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [],
				"name": "vaultManager",
				"outputs": [
					{
						"internalType": "contract IVaultManager",
						"name": "",
						"type": "address"
					}
				],
				"stateMutability": "view",
				"type": "function"
			},
			{
				"inputs": [
					{
						"internalType": "address",
						"name": "_upperHint",
						"type": "address"
					},
					{
						"internalType": "address",
						"name": "_lowerHint",
						"type": "address"
					}
				],
				"name": "withdrawETHGainToVault",
				"outputs": [],
				"stateMutability": "nonpayable",
				"type": "function"
			},
			{
				"stateMutability": "payable",
				"type": "receive"
			}
		],
		address: {
			4: '0x9a0acB710aC0aB1c44daac0001C75840eE3a9E44',
			5: '0xCa722a8F5be5b3ABFD28dD654b0EB530Fd946fBB'
		}
	},
	vaultManager: {
		name: "vaultManager",
		abi: [
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_activePoolAddress",
				type: "address",
			  },
			],
			name: "ActivePoolAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_baseRate",
				type: "uint256",
			  },
			],
			name: "BaseRateUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_newBorrowerOperationsAddress",
				type: "address",
			  },
			],
			name: "BorrowerOperationsAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_collSurplusPoolAddress",
				type: "address",
			  },
			],
			name: "CollSurplusPoolAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_defaultPoolAddress",
				type: "address",
			  },
			],
			name: "DefaultPoolAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_gasPoolAddress",
				type: "address",
			  },
			],
			name: "GasPoolAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_L_ETH",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_L_USDAODebt",
				type: "uint256",
			  },
			],
			name: "LTermsUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_lastFeeOpTime",
				type: "uint256",
			  },
			],
			name: "LastFeeOpTimeUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_liquidatedDebt",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_liquidatedColl",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_collGasCompensation",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_USDAOGasCompensation",
				type: "uint256",
			  },
			],
			name: "Liquidation",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			  },
			],
			name: "OwnershipTransferred",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_attemptedUSDAOAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_actualUSDAOAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_ETHSent",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_ETHFee",
				type: "uint256",
			  },
			],
			name: "Redemption",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_sortedVaultsAddress",
				type: "address",
			  },
			],
			name: "SortedVaultsAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_stakingPoolAddress",
				type: "address",
			  },
			],
			name: "StakingPoolAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_totalStakesSnapshot",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_totalCollateralSnapshot",
				type: "uint256",
			  },
			],
			name: "SystemSnapshotsUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_newTotalStakes",
				type: "uint256",
			  },
			],
			name: "TotalStakesUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_newUSDAOokenAddress",
				type: "address",
			  },
			],
			name: "USDAOTokenAddressChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_newIndex",
				type: "uint256",
			  },
			],
			name: "VaultIndexUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_debt",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_coll",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "enum IVaultManager.VaultManagerOperation",
				name: "operation",
				type: "uint8",
			  },
			],
			name: "VaultLiquidated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "uint256",
				name: "_L_ETH",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_L_USDAODebt",
				type: "uint256",
			  },
			],
			name: "VaultSnapshotsUpdated",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_debt",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "_coll",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "stake",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "enum IVaultManager.VaultManagerOperation",
				name: "operation",
				type: "uint8",
			  },
			],
			name: "VaultUpdated",
			type: "event",
		  },
		  {
			inputs: [],
			name: "BETA",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "BOOTSTRAP_PERIOD",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "BORROWING_FEE_FLOOR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "CCR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "DECIMAL_PRECISION",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "L_ETH",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "L_USDAODebt",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "MAX_BORROWING_FEE",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "MCR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "MINUTE_DECAY_FACTOR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "NAME",
			outputs: [
			  {
				internalType: "string",
				name: "",
				type: "string",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "PERCENT_DIVISOR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "REDEMPTION_FEE_FLOOR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "SECONDS_IN_ONE_MINUTE",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "USDAO_GAS_COMPENSATION",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			name: "VaultOwners",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			name: "Vaults",
			outputs: [
			  {
				internalType: "uint256",
				name: "debt",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "coll",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "stake",
				type: "uint256",
			  },
			  {
				internalType: "enum VaultManager.Status",
				name: "status",
				type: "uint8",
			  },
			  {
				internalType: "uint128",
				name: "arrayIndex",
				type: "uint128",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "_100pct",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "addVaultOwnerToArray",
			outputs: [
			  {
				internalType: "uint256",
				name: "index",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "applyPendingRewards",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "baseRate",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address[]",
				name: "_vaultArray",
				type: "address[]",
			  },
			],
			name: "batchLiquidateVaults",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "borrowerOperationsAddress",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "_price",
				type: "uint256",
			  },
			],
			name: "checkRecoveryMode",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "closeVault",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "decayBaseRateFromBorrowing",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_collDecrease",
				type: "uint256",
			  },
			],
			name: "decreaseVaultColl",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_debtDecrease",
				type: "uint256",
			  },
			],
			name: "decreaseVaultDebt",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "defaultPool",
			outputs: [
			  {
				internalType: "contract IDefaultPool",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "_USDAODebt",
				type: "uint256",
			  },
			],
			name: "getBorrowingFee",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "_USDAODebt",
				type: "uint256",
			  },
			],
			name: "getBorrowingFeeWithDecay",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getBorrowingRate",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getBorrowingRateWithDecay",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_price",
				type: "uint256",
			  },
			],
			name: "getCurrentICR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getEntireDebtAndColl",
			outputs: [
			  {
				internalType: "uint256",
				name: "debt",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "coll",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "pendingUSDAODebtReward",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "pendingETHReward",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getEntireSystemColl",
			outputs: [
			  {
				internalType: "uint256",
				name: "entireSystemColl",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getEntireSystemDebt",
			outputs: [
			  {
				internalType: "uint256",
				name: "entireSystemDebt",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getNominalICR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getPendingETHReward",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getPendingUSDAODebtReward",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "_price",
				type: "uint256",
			  },
			],
			name: "getTCR",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getVaultColl",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getVaultDebt",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "_index",
				type: "uint256",
			  },
			],
			name: "getVaultFromVaultOwnersArray",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "getVaultOwnersCount",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getVaultStake",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "getVaultStatus",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "hasPendingRewards",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_collIncrease",
				type: "uint256",
			  },
			],
			name: "increaseVaultColl",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_debtIncrease",
				type: "uint256",
			  },
			],
			name: "increaseVaultDebt",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "lastETHError_Redistribution",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "lastFeeOperationTime",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "lastUSDAODebtError_Redistribution",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "liquidate",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "_n",
				type: "uint256",
			  },
			],
			name: "liquidateVaults",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "owner",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "removeStake",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "renounceOwnership",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			name: "rewardSnapshots",
			outputs: [
			  {
				internalType: "uint256",
				name: "ETH",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "USDAODebt",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrowerOperationsAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_defaultPoolAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_stakingPoolAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_gasPoolAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_collSurplusPoolAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_usmAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_sortedVaultsAddress",
				type: "address",
			  },
			],
			name: "setAddresses",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "_num",
				type: "uint256",
			  },
			],
			name: "setVaultStatus",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "sortedVaults",
			outputs: [
			  {
				internalType: "contract ISortedVaults",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "stakingPool",
			outputs: [
			  {
				internalType: "contract IStakingPool",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "totalCollateralSnapshot",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "totalStakes",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "totalStakesSnapshot",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "newOwner",
				type: "address",
			  },
			],
			name: "transferOwnership",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "updateStakeAndTotalStakes",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_borrower",
				type: "address",
			  },
			],
			name: "updateVaultRewardSnapshots",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "usm",
			outputs: [
			  {
				internalType: "contract IUSM",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		],
		address: {
		  4: "0x57CeA51aC84A447Eef9A5429E9bF12E6F1578D2b",
		  5: "0x35Bd54bAD498A4Ed25fe5d669c3869fc7DD4a075",
		},
	  },
}
