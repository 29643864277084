import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { loginMetaMask } from "../../blockchain/tradingSdk";
import { getAccoundDetails } from "../../blockchain/commonFunction";
import { PopoverComp } from '../../util/utilComponent';
import "../home/Home.scss";

export const NavBar = ({ darkMode, setDarkMode }) => {
  const [userDetails, setUserDetails] = useState();
  const [isMobile, setIsMobile] = useState(false);
  const [mobileStatus, setMobileStatus] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 600);
    (async () => {
      const metamaskDetails = await getAccoundDetails();
      setUserDetails(metamaskDetails);
      if (metamaskDetails.networkType !== "goerli") {
        swal("Please connect to Goerli Account/Wallet.");
      }
    })();
  }, []);

  if (window.ethereum) {
    window.ethereum.on("chainChanged", function (networkId) {
      swal("Please connect to Goerli Account/Wallet.");
      window.location.reload();
    });
  }

  const copyAddress = () => {
    if (!navigator.clipboard) {
      return
    }
    setCopyStatus(true)
    navigator.clipboard.writeText(userDetails.address)
  }

  loginMetaMask();
  const MenuItem = () => {
    return (
      <div className="justify-content-center align-items-center menuElement">
        {mobileStatus &&
          <p>
            <img className="close-icon" onClick={() => setMobileStatus(false)} src={require("../../../assets/images/close.png")} />
          </p>
        }

        <p className="common-white-font px-4 pointer m-0 hidden">
          <a href="/#/app">
            App
          </a>
        </p>
        <p className="common-white-font px-4 pointer m-0 hidden">
          <a target="_blank" href="/#/staking">
            Staking
          </a>
        </p>
        <p className="common-white-font px-4 pointer m-0 hidden">
          <a target="_blank" href="/#/governance">
            Governance
          </a>
        </p>
        {/* <p className='common-white-font px-2 pointer m-0 mode-click'>
					<img
						src={require('../../assets/images/sun.svg')}
						alt=''
						onClick={(e) => setValue()}
					/>
				</p> */}
        <p className="common-white-font px-4 pointer m-0">
          <a
            target="_blank"
            href="https://dawn-mode-5816.on.fleek.co/#/dashboard"
            className="btn btn-outline onvault-menu"
          >OnVault</a>
        </p>
        {userDetails && (
          <div className="common-white-font pointer m-0">
            <p className='account-value'>
              {' '}
              <div className='btn btn-outline copy-address' id='copy-add' onClick={copyAddress} onMouseMove={() => setCopyStatus(false)}>
                <div className='copy-popup'>
                  <PopoverComp content={copyStatus ? 'Copied' : 'Copy'} />
                </div>
                {userDetails.address.substring(0, 5)}...
                {userDetails.address.substring(userDetails.address.length - 5)}
              </div>
            </p>
          </div>
        )}
        {userDetails && (
          <>
            <p className="account-value">
              <img src="../../assets/ethereum.png" alt="" />{" "}
              {userDetails.networkType}{" "}
            </p>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      {mobileStatus && (
        <div className="menuWrapper">
          <MenuItem />
        </div>
      )}
      <div className="home-navbar common-nav " style={{ width: "100%" }}>
        <div className="logoWrap">
          <a href=" https://usdao.io/">
            <img
              src={require("../../../assets/images/home_logo.png")}
              alt=""
            // onClick={() => {
            // 	history.push('/')
            // }}
            />
          </a>
        </div>

        {isMobile ? <img
          src={require("../../../assets/images/menu.png")}
          alt=""
          onClick={() => {
            setMobileStatus(true)
          }}
        /> : <MenuItem />}
      </div>
    </>
  );
};
