import React, { useState, useEffect } from 'react';
import { NavBar } from '../common/Header';
import LeftSideCard from './leftSideCards';
import RightSideCard from './rightSideCard';
import { contractDetails } from "../../blockchain/contractDetails"
import { BigNumber, ethers } from 'ethers';
import Swal from "sweetalert2"
import { fetchInitialWeb3Data, fetchAllInitialValues } from '../home/helper';
import './Home.scss';

const Home = () => {

    const [prices, setPrices] = useState({})


    const [price, setPrice] = useState(null)
    const [bal, setBal] = useState(null)
    const [usmBal, setUsmBal] = useState(null)
    const [fumBal, setFumBal] = useState(null)
    const [totalColl, setTotalColl] = useState(null)
    const [buffer, setBuffer] = useState(null)
    const [debtR, setDebtR] = useState(null)
    const [stakedAmt, setStakedAmt] = useState(null)


    const loadBlockchainData = async () => {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        const _address = await signer.getAddress();
        let _contractUSM = new ethers.Contract(contractDetails.usm.address["5"], contractDetails.usm.abi, provider);
        let _contractFUM = new ethers.Contract(contractDetails.fum.address["5"], contractDetails.fum.abi, provider);
        let p = await _contractUSM.latestPrice();
        setPrice(ethers.utils.formatEther(p))
        const balance = await provider.getBalance(_address);
        setBal(ethers.utils.formatEther(balance))
        let usmBalance = await _contractUSM.balanceOf(_address);
        setUsmBal(ethers.utils.formatEther(usmBalance));
        let fumBalance = await _contractFUM.balanceOf(_address);
        setFumBal(ethers.utils.formatEther(fumBalance))
        let ethPool = await _contractUSM.ethPool();
        if (ethPool.eq(BigNumber.from("0"))) {
            Swal.fire({
                icon: 'error',
                html: `<p>Buying/Selling is disabled during the Prefund stage!</p>`
            })
        }
        let e = ethers.utils.formatEther(ethPool) * ethers.utils.formatEther(p)
        setTotalColl(e);
        let t = await _contractUSM.totalSupply();
        let totalSup = ethers.utils.formatEther(t);
        setBuffer(e - totalSup);
        let r = await _contractUSM.debtRatio(p, ethPool, t);
        setDebtR(ethers.utils.formatEther(r) * 100);
        let _contractSP = new ethers.Contract(contractDetails.stakingPool.address["5"], contractDetails.stakingPool.abi, provider);
        let _stakedAmt = await _contractSP.getCompoundedUSDAODeposit(_address);
        setStakedAmt(ethers.utils.formatEther(_stakedAmt));
    }

    useEffect(() => {
        ; (async () => {
            setInterval(async()=>{
                await loadBlockchainData();
            }, 5000)
            
            const accountDetails = await fetchInitialWeb3Data()
            const values = await fetchAllInitialValues()
            setPrices((state) => ({ ...state, ...accountDetails, ...values.prices }))
        })()
    }, [])

    useEffect(() => {
    }, [])

    return (
        <>
            <div className='home-container'>
                <div className=''>
                    <NavBar />
                    <div className='division-2 pb-5  mt-0 pt-4'>
                        <div className='row g-5'>
                            <LeftSideCard prices={prices}
                                setPrices={setPrices}
                                price={price}
                                bal={bal}
                                usmBal={usmBal}
                                fumBal={fumBal}
                                totalColl={totalColl}
                                buffer={buffer}
                                debtR={debtR}
                                stakedAmt={stakedAmt}
                            />
                            <RightSideCard prices={prices} setPrices={setPrices} refreshBlockChainData={loadBlockchainData} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;