import React, { useState, useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios';
import moment from 'moment'
import { loginMetaMaskFn } from '../../blockchain/tradingSdk';
import { loadProposals, getStateClassName, getStateName, endDateProposalMethod, voteProposal, queueProposal, getProposalDetails } from './proposalListUtil';

const ProposalList = ({ votes }) => {
	const [proposalLoading, setProposalLoading] = useState(false);
	const [disabled, setDisabled] = useState(false)

	const [proposalDetails, setProposalDetails] = useState({
		proposals: null,
		proposalStates: null,
		current_block: 0,
		responses: null
	})
	const [showModal, setShowModal] = useState(false);
	const [settedId, setSettedId] = useState(0)
	const [settedData, setSettedData] = useState({})
	const [endingDateProposal, setEndingDateProposal] = useState();
	const [proposalData, setProposalData] = useState(null)
	const [disabledQueueBtn, setDisabledQueueBtn] = useState(false)
	const [disabledExecuteBtn, setDisabledExecuteBtn] = useState(false)
	const [loginStatus, setLoginStatus] = useState(false);
	const [proposalDeatails, setProposalDeatails] = useState({});
	const [proposalCreatedAt, setProposalCreatedAt] = useState();

	const [total, setTotal] = useState({
		for: 0,
		against: 0
	})

	useEffect(async () => {
		const metamaskStatus = await loginMetaMaskFn();
		setLoginStatus(metamaskStatus);
		setProposalLoading(true)
		loadProposals(setProposalLoading, setProposalDetails);

	}, [])


	const showModalForProp = async (value, index) => {
		setSettedId(Number(value['id']))

		setSettedData({
			v: value,
			i: index
		})

		const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}governance/view-desc`, {
			params: {
				desc_id: Number(value['id'])
			}
		})
		const proposalDetailsSingle = await getProposalDetails(Number(value['id']));
		setProposalDeatails(proposalDetailsSingle);
		if (data && data.success && data.message) {
			let totals = {
				for: 0,
				against: 0
			}

			for (const vote of data.message.votes) {
				if (Number(vote.vote_type) === 1) {
					totals.for += Number(vote.value)
				} else {
					totals.against += Number(vote.value)
				}
			}
			setTotal(totals)
			setProposalData(data.message)
			setProposalCreatedAt(data.message.createdAt)
			endDateProposalMethod(data.message.createdAt, setEndingDateProposal)
			setEndingDateProposal("...")
		}
		setShowModal(true)
		let element = document.getElementById('governance')
		element.classList.add("pointer-none");
		document.body.style.overflow = 'hidden';
	}

	const closeModal = () => {
		setShowModal(false)
		let element = document.getElementById('governance')
		element.classList.remove("pointer-none");
		document.body.style.overflow = 'unset';
	}

	return (
		<>
			<div className='row pt-5 mt-5 mb-3 d-flex justify-content-end align-items-center'>
				<div className='col-md-6 '>
					<p className='orange-color medium-font'>Proposals</p>
				</div>
				<div className='col-md-6 text-right d-flex justify-content-end'>
					<div className='caption-content mr-md-3'>Refresh</div>
					<img
						src={require('../../../assets/images/coolicon.svg')}
						alt=''
						className='pointer'
						style={{ height: '20px' }}
						onClick={() => {
							loadProposals(setProposalLoading, setProposalDetails);
						}}
					/>
				</div>
			</div>
			<div className='proposalWrapper'>
				{proposalLoading ? (
					<div
						className='col-md-12 loading text-center'
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						{' '}
						{/* <div className='spinner'> */}
						<ThreeDots
							color='#F85E11'
							height={100}
							width={100}
							style={{ justifyContent: 'center' }}
						/>
						{/* </div> */}
					</div>
				) : (
					(!!proposalDetails.proposals &&
						proposalDetails.proposals.length) ?
						proposalDetails.proposals.map((v, i) => (
							<div className='row mt-2 py-2 align-items-center justify-content-center' key={i} >
								<div className='col-md-12 keyboard-shadow'>
									<div className='row py-3 px-4 align-items-center justify-content-center'>
										<div className='col-md-1 d-flex justify-content-center align-items-center'>
											<p className='content-caption'>{Number(v['id'])}</p>
										</div>
										<div className='col-md-9 d-flex align-items-center proposal-label'>
											<div
												className='caption-content-prop pointer'
												onClick={() => showModalForProp(v, i)}
											>
												{
													!!proposalDetails.responses && proposalDetails.responses.find(
														(val) => Number(v['id']) === Number(val.args['id'])
													)?.['args']?.[8]
												}
											</div>
										</div>
										<div className='col-md-2 d-flex justify-content-center align-items-center'>
											<button
												className={proposalDetails.proposalStates && getStateClassName(
													getStateName(proposalDetails.proposalStates[i])
												)}
											>
												{proposalDetails.proposalStates && getStateName(proposalDetails.proposalStates[i])}
											</button>
										</div>
									</div>
								</div>


							</div>
						)) :
						<div className='col-md-12 loading not-connected'>

							{!loginStatus ? <h3>Please log-in to your Metamask and connect to Goerli Network.</h3> :

								<h3>No Proposal(s) Available.</h3>}
						</div>

				)}

			</div>

			{showModal && (
				<>
					<div className='prop-modal'>
						<div className='row my-0'>
							<div className='col text-right d-flex justify-content-end align-items-top'>
								{/* <p className='caption-content-prop px-2 pt-2'>Ended</p>
								<p className='caption-content-prop px-2 pt-2'>On-Going</p> */}
								<img
									src={require('../../../assets/images/modal-close.svg')}
									alt=''
									className='mx-2 pointer'
									style={{ marginTop: '-10px' }}
									onClick={closeModal}
								/>
							</div>
						</div>
						<div className='row my-0 mx-3 mb-2 popup-title'>
							<div className='col mx-1'>
								<div
									className='caption-content-prop'
									style={{ fontSize: '18px' }}
								>
									{settedId}{'. '}
									{
										!!proposalDetails.responses && proposalDetails.responses.find(
											(val) => Number(settedId) === Number(val.args['id'])
										)?.['args']?.[8]
									}
								</div>
							</div>
						</div>

						<div className='row mx-3'>
							<div className='col-md-6'>
								<p className='label-content'>For Vote</p>
								<input
									type='text'
									className='input-content mt-2 mb-4'
									placeholder='0'
									disabled
									value={total.for}
								/>
								<p className='label-content'>Against Vote</p>
								<input
									type='text'
									className='input-content mt-2 mb-4'
									placeholder='0'
									disabled
									value={total.against}
								/>
								<div className='d-flex'>
									{proposalDetails.proposalStates && getStateName(
										proposalDetails.proposalStates[settedData.i]
									) === 'Active' &&
										(
											<div
												className='d-flex'
												style={{ direction: 'rtl' }}
											>
												<button
													className={
														disabled
															? 'prop-button-success my-2 mx-2 disabled cursor-pointer'
															: 'prop-button-success my-2 mx-2 cursor-pointer'
													}
													style={{ color: 'white' }}
													onClick={() =>
														voteProposal(
															settedData,
															true,
															setDisabled,
															settedData.i,
															votes,
															setProposalLoading,
															setProposalDetails,
															setShowModal,
															proposalCreatedAt
														)
													}
												>
													Accept
												</button>
												<button
													className={
														disabled
															? 'prop-button-success my-2 mx-2 disabled cursor-pointer'
															: 'prop-button-success my-2 mx-2 cursor-pointer'
													}
													style={{ color: 'white' }}
													onClick={() =>
														voteProposal(
															settedData,
															false,
															setDisabled,
															settedData.i,
															votes,
															setProposalLoading,
															setProposalDetails,
															setShowModal,
															proposalCreatedAt
														)
													}
												>
													Reject
												</button>
											</div>
										)}
									{proposalDetails.proposalStates && getStateName(
										proposalDetails.proposalStates[settedData.i]
									) === 'Succeeded' && (
											<button
												className={
													disabledQueueBtn
														? 'prop-button-success my-2 mx-2 disabled cursor-pointer'
														: 'prop-button-success my-2 mx-2 cursor-pointer'
												}
												style={{ color: 'white' }}
												onClick={() =>
													queueProposal(
														settedData.v['id'],
														setDisabledQueueBtn,
														votes,
														"queue",
														setProposalLoading,
														setProposalDetails,
														setShowModal)
												}
											>
												Queue
											</button>
										)}

									{proposalDetails.proposalStates && getStateName(
										proposalDetails.proposalStates[settedData.i]
									) === 'Queued' &&
										moment().unix() > Number(settedData.v['eta']) && (
											<button
												className={
													disabledExecuteBtn
														? 'prop-button-success my-2 mx-2 disabled cursor-pointer'
														: 'prop-button-success my-2 mx-2 cursor-pointer'
												}
												style={{ color: 'white' }}
												onClick={() =>
													queueProposal(
														settedData.v['id'],
														setDisabledExecuteBtn,
														votes,
														"execute",
														setProposalLoading,
														setProposalDetails,
														setShowModal,
														settedData.v['eta']
													)
												}
											>
												Execute
											</button>
										)}
								</div>
							</div>
							<div className='col-md-6'>
								<p className='caption-content-prop py-1'>
									Proposer : {settedData.v.proposer}
								</p>
								<p className='caption-content-prop py-1'>
									Target : {proposalDeatails.target}
								</p>
								<p className='caption-content-prop py-1'>
									Signatures :  {proposalDeatails.sign}
								</p>
								<p className='caption-content-prop py-1'>
									Proposal Start Date :{' '} {proposalData && proposalData.createdAt && moment(proposalData.createdAt).format('DD/MM/YYYY HH:mm')}
								</p>
								<p className='caption-content-prop py-1'>
									Against Vote :{' '}
									{(
										(Number(settedData.v['againstVotes']) /
											10e17 /
											21000000) *
										100
									).toFixed(2)}{' '}
									%
								</p>
								<p className='caption-content-prop py-1'>
									For Vote :{' '}
									{(
										(Number(settedData.v['forVotes']) /
											10e17 /
											21000000) *
										100
									).toFixed(2)}{' '}
									%
								</p>
								<p className='caption-content-prop py-1'>Ends in: {endingDateProposal} </p>
							</div>
						</div>

						<div className='row mx-4'>
							<div className='col-md-12'>
								<p className='caption-content-prop py-2'>Details: <br /><span dangerouslySetInnerHTML={{
									__html: proposalData && proposalData.description
								}}></span></p>
							</div>
							{/* <p className='caption-content py-2'>
							{proposalData?.description} Read More ...
						</p> */}
						</div>
					</div>
					<div className='overlay'></div>
				</>

			)}
		</>
	)
}

export { ProposalList };