import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect, BrowserRouter, HashRouter } from 'react-router-dom'
import { GovernancePage, HomeV2, Stakev2 } from './v2/pages';
import { RevenueAdmin, GovernanceAdmin, Fee } from './v2/admin';
import './App.scss'
// import Stake from './home/Stake'

import 'antd/dist/antd.css'

require('dotenv').config();


class AppRoutes extends Component {
	render() {
		return (
			<Suspense fallback={<div>...Loading</div>}>
				<HashRouter  hasType = "hashbang">
				<Switch>
					<Route exact path='/app' component={HomeV2} />
					<Route exact path='/governance' component={GovernancePage} />
					<Route exact path='/staking' component={Stakev2} />
					<Route exact path='/admin/revenue' component={RevenueAdmin} />
					<Route exact path='/admin/governance' component={GovernanceAdmin} />
					<Route exact path='/admin/fee' component={Fee} />
					<Redirect to='/app' />
				</Switch>
				</HashRouter>
			</Suspense>
		)
	}
}

export default AppRoutes