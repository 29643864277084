import moment from 'moment'
import axios from 'axios';
import swal from 'sweetalert'
import { ethers } from 'ethers'

import {capitalizeFirstLetter} from '../common/util'
import {getAddress, writeContractFunction, provider as infuraProvider} from '../../blockchain/tradingSdk';
import { targetState, singnatureFunction } from './helper';


const getStateName = (id) => {
    let status = [
        'Pending',
        'Active',
        'Canceled',
        'Defeated',
        'Succeeded',
        'Queued',
        'Expired',
        'Executed'
    ]
    return status[id]
}

const getStateClassName = (name) => {
    let successStatus = ['Pending', 'Active', 'Succeeded', 'Queued', 'Executed']

    if (successStatus.includes(name)) {
        return 'prop-button-success'
    } else {
        return 'prop-button-danger'
    }
}

const loadProposals = async (setProposalLoading, setProposalDetails) => {
	
	try{
		setProposalLoading(true)
        const GovContract = await writeContractFunction('governorAlpha');
        const current_block = await infuraProvider.getBlockNumber()

		const proposals = await GovContract.proposalCount()
		const proposalCount = []
		const startNumber = Number(proposals) > 20 ? Number(proposals) - 20 : 1

		for (let i = startNumber; i <= Number(proposals); i++) {
			proposalCount.push(i)
		}

		// setProposalLoading(0)

		setProposalDetails({
			proposals: null,
			proposalStates: null,
			current_block: 0,
			responses: null
		})

		const proposalPromise = new Promise(async (resolve, reject) => {
			try {
				let _proposals = []

				let l = 1
				if (proposalCount.length > 0) {
					for (const i of proposalCount) {
						const data = await GovContract.proposals(i)
						_proposals.unshift(data)
						const perc = (l * 100) / proposalCount.length
						l++
					}
				}
				resolve(_proposals)
			} catch (error) {
				setProposalLoading(false)
				reject(error)
			}
		})

		const proposalStatePromise = new Promise(async (resolve, reject) => {
			try {
				let _proposalStates = []

				if (proposalCount.length > 0) {
					for (const i of proposalCount) {
						const resp = await GovContract.state(i)
						_proposalStates.unshift(resp)
					}
				}
				resolve(_proposalStates)
			} catch (error) {
				setProposalLoading(false)
				reject(error)
			}
		})

		const result = await Promise.all([proposalPromise, proposalStatePromise])
		const _proposals = result[0],
			_proposalStates = result[1]

		if (_proposals.length > 0 && _proposalStates.length > 0) {
			const startBlock = _proposals[_proposals.length - 1],
				endBlock = _proposals[0]

			const response = await GovContract.queryFilter(
				'ProposalCreated',
				Number(startBlock['startBlock']) - 500,
				Number(endBlock['endBlock'])
			)

			const uniqueResponse = []
			for (const r of response) {
				const exist = uniqueResponse.find(
					(ur) => ur.blockNumber === r.blockNumber
				)
				if (!exist) {
					uniqueResponse.push(r)
				}
			}
			setProposalDetails({
				proposalStates: _proposalStates,
				proposals: _proposals,
				responses: uniqueResponse,
				current_block
			})
			setProposalLoading(false)

			let dbProposals = [],
				pInd = 0
			for (const p of _proposals) {
				dbProposals.unshift({
					id: Number(p['id']),
					status: getStateName(_proposalStates[pInd])
				})
				pInd++
			}
			//storeToDb(dbProposals)
		}else if(!_proposals.length){
			setProposalLoading(false)
		}

	}catch{
		setProposalLoading(false)
	}
	
	}

    const endDateProposalMethod = async(endDateProposal, setEndingDateProposal) =>{
        const GovContract = await writeContractFunction('governorAlpha');
		const strtingdate = moment(endDateProposal).unix();
		const votingPeriod = Number(await GovContract.votingPeriod() * 15)
		const endibngDate = strtingdate + votingPeriod;
		const finalendDateProposal = moment.unix(endibngDate).format("DD/MM/YYYY HH:mm");
		setEndingDateProposal(finalendDateProposal);
	
	};

	const voteProposal = async (settedData, approvalState, setDisabled, index,votes, setProposalLoading, setProposalDetails, setShowModal, proposalCreatedAt) => {
        const contractId = settedData.v['id'];
		const forVote = settedData.v['forVotes'];
		const againstVote = settedData.v['againstVotes'];
		const CompContract = await writeContractFunction('comp');
        const GovContract = await writeContractFunction('governorAlpha');
        const signerAddress = await getAddress();
		let element = document.getElementById('governance')
		element.classList.remove("pointer-none");
		document.body.style.overflow = 'unset';

		const strtingdate = moment(proposalCreatedAt).unix();
		const votingPeriod = Number(await GovContract.votingPeriod() * 15)
		const endibngDate = strtingdate + votingPeriod;
		const currentDate = new Date();
		if (!votes.delegatedVotes) {
			swal(`You don't have suffficient delegated votes to create proposal `)
			return
		}
		if(new Date(endibngDate * 1000) < currentDate ){
			if(Number(forVote) > Number(againstVote)){
				swal('This proposal has been succeeded successfully');

			}
			else{
				swal('This proposal is already Defeated. You can not vote now.');

			}
			return
		}
		try {
			let delegatedVotes = await CompContract.getCurrentVotes(signerAddress)
			delegatedVotes = Number(ethers.utils.formatEther(delegatedVotes))

			if (delegatedVotes < 1)
				swal(
					`You don't have suffficient delegated votes to vote in a proposal `
				)

			let proposal

			try {
				proposal = await GovContract.castVote(contractId, approvalState)
				if (proposal) {
					let { hash } = proposal
					setDisabled(true)
					swal(`Please wait as your request is being processed.`)

					const result = await infuraProvider.waitForTransaction(hash)

					setDisabled(false)

					if (result.status) {
						
						await axios.post(`${process.env.REACT_APP_BACKEND_URL}governance/add-votes`, {
							user_hash: result.from,
							desc_id: Number(contractId),
							vote_type: approvalState ? 1 : 0,
							value: delegatedVotes
						})
						swal(`Your vote has been registered successfully.`).then(()=>{
							// setTimeout(()=>{
							// 	window.location.reload();

							// }, 2000)
							setShowModal(false)
							loadProposals(setProposalLoading, setProposalDetails);
						})
						//refreshSingleContract(contractId)
					} else swal(`Your transaction is failed`)
				}
			} catch (e) {
				if (e.code === 4001) return swal(`User denied transaction signature.`)
				if (String(e).includes('proposer votes below proposal threshold'))
					swal(`User dosen’t have enough tokens delegated to create a proposal`)
				else swal(`You have already voted for this Proposal.`)
				return
			}
		} catch (error) {
			swal('Something went wrong, please try again !')
		}
	}    

	const queueProposal = async (id, setDisabledQueueBtn, votes, action, setProposalLoading, setProposalDetails, setShowModal, eta) => {
		let element = document.getElementById('governance')
		element.classList.remove("pointer-none");
		document.body.style.overflow = 'unset';

		if (!votes.delegatedVotes) {
			swal(`You don't have suffficient delegated votes to create proposal `)
			return
		}
		if(action === "execute"){

			const currentDate = new Date();
			eta = new Date(Number(eta) * 1000)
			eta.setTime(eta.getTime() + (3 * 60 * 1000));

			if(currentDate > new Date(eta)){
				swal(`This proposal has expired now.`)
				return
			}
		}
		
		try {
			const GovContract = await writeContractFunction('governorAlpha');
			let queue
			try {
				if(action === "execute"){
				
					queue = await GovContract.execute(id)
				}
				else{

					queue = await GovContract.queue(id)

				}	
			} catch (e) {
				if (e.code === 4001) {
					return swal('User denied transaction.')
				}
				if (
					String(e).includes('proposal can only be queued if it is succeeded')
				) {
					return swal('This proposal is already Queued.')
				}
			}
			if (queue) {
				setDisabledQueueBtn(true)
				swal(`Please wait as your request is being processed.`)
				const result = await infuraProvider.waitForTransaction(queue.hash)

				setDisabledQueueBtn(false)
				if (result) {
					if(action === "execute"){
						swal(`Your proposal has been ${capitalizeFirstLetter(action)}d Successfully!`).then(()=>{
							setTimeout(()=>{
								window.location.reload();
	
							}, 2000)
						})
					}else if(action === "queue"){
						swal(`Proposal  ${capitalizeFirstLetter(action)}d Successfully!`).then(()=>{
							// setTimeout(()=>{
							// 	window.location.reload();
	
							// }, 2000)
							setShowModal(false)
							loadProposals(setProposalLoading, setProposalDetails);
						})
					}else{
						swal(`${capitalizeFirstLetter(action)} Successfully!`).then(()=>{
							setTimeout(()=>{
								window.location.reload();
	
							}, 2000)
						})
					}
					
					
					//refreshSingleContract(id)
				} else swal(`Please try again`)
			}
		} catch (error) {
			swal(`Something went wrong, please try again !`)
		}
	}

	const getProposalDetails = async(pro_id) =>{
		const GovContract = await writeContractFunction('governorAlpha');
		
		const actionDetails = await GovContract.getActions(pro_id);
		const sign = actionDetails.signatures[0];
		const target = actionDetails.targets[0];
		const signObj = singnatureFunction.filter((item)=>item.value === sign);
		const stateObj = targetState.filter((item) => item.value === target);
		return {sign: !!signObj.length && signObj[0].label, target: !!stateObj.length && stateObj[0].label};
	}
export {loadProposals, getStateClassName, getStateName, endDateProposalMethod, voteProposal, queueProposal, getProposalDetails}
