import { ethers } from 'ethers';
import web3 from 'web3';
import swal from 'sweetalert';

const getAccoundDetails = () => {
	return new Promise((resolve, reject) => {
		var myWeb3 = new web3(window.ethereum) //window.web3.currentProvider
		myWeb3.eth.getAccounts(async (err, accounts) => {
			if (err) {
				console.log("error", err)
			} else {
				if (typeof accounts !== 'object' || typeof accounts[0] !== 'string') {
					reject('No accounts set')
				}

				const details = {};

				let networkType = await myWeb3.eth.net.getNetworkType()
				details.networkType = networkType;
				details.address = accounts[0];
				details.balance = ethers.utils.formatUnits(await myWeb3.eth.getBalance(accounts[0]), 18)
				resolve(details);
				//return details;
			}
		})
	})

}
const provider = () => {
	let provider;
	if (typeof window.ethereum !== 'undefined') {
		provider = new ethers.providers.Web3Provider(window.ethereum);
	}
	return provider
}

export { getAccoundDetails, provider };


