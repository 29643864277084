import { ethers } from 'ethers';
import moment from 'moment'
import swal from 'sweetalert';
import {readContractFunction, writeContractFunction, getContractAddress, getAddress} from '../../blockchain/tradingSdk'

const _ = require('lodash')

export const fetchStakeAmountFn = async () => {
	const contract = await readContractFunction('stake')
	const loggedInAddress = await getAddress()
	const chk = await contract.queryFilter('Lock')
	const unchk = await contract.queryFilter('Unlock')
	const claim = await contract.queryFilter('Claim')

	const StakeContract = await writeContractFunction('stake')

	return { chk, loggedInAddress, StakeContract, unchk, claim }
}

export const fetchStaked = async(setStakedAmount) => {
	let { chk, loggedInAddress, unchk } = await fetchStakeAmountFn(),
		totalStakedAmount = 0

	for (let i = 0; i < chk.length; i++) {
		if (chk[i]) {
			const ofAddress = chk[i].args['holder']
			if (ofAddress === loggedInAddress) {
				var value = ethers.utils.formatEther(String(chk[i].args['_value']))
				var rF = parseFloat(value).toFixed(3)
				totalStakedAmount = parseFloat(totalStakedAmount) + parseFloat(rF)
				var lStakeId = parseInt(chk[i].args['StakeId'])
				for (let j = 0; j < unchk.length; j++) {
					var unStakeId = parseInt(unchk[j].args['_stakId'])
					if (lStakeId === unStakeId) {
						totalStakedAmount = parseFloat(totalStakedAmount) - parseFloat(rF)
					}
				}
			}
		}
	}
	setStakedAmount && setStakedAmount(totalStakedAmount)
	return totalStakedAmount
}

export const fetchAvailableBalance = async (setAvailablebalance) => {
	const contract = await readContractFunction('stake');
	const loggedInAddress = await getAddress();
	const amount = await contract.getUSDAObalance(loggedInAddress)
	const AvailableBalance = ethers.utils.formatUnits(amount, 18)
	setAvailablebalance(AvailableBalance)
}


export const claimTable = async (
	setClaim_Table,
	setStakedAmount,
	setDisableClaimbtn,
	setLoader
) => {
	let { chk, loggedInAddress, StakeContract, unchk, claim } =
		await fetchStakeAmountFn()
	let data_arr = []
	var totalStakedAmount = 0,
		claim_enableTime
	for (let i = 0; i < chk.length; i++) {
		if (chk[i]) {
			const ofAddress = chk[i].args['holder']
			if (ofAddress === loggedInAddress) {
				var lStakeId = parseInt(chk[i].args['StakeId'])
				const finalData = await StakeContract.calculateReward(lStakeId)
				
				var stakeStartTIme = moment
					.unix(Number(chk[i].args['_startTime']))
					.format('DD MMM YYYY')
				
				var claimedSatus = await StakeContract.getLockStatus(
					chk[i].args['StakeId']);

				var value = ethers.utils.formatEther(String(chk[i].args['_value']))
				for (let k = 0; k < claim.length; k++) {
					var claimStakeId = parseInt(claim[k].args['_stakId'])
					if (lStakeId === claimStakeId) {
						claim_enableTime = parseInt(claim[k].args._nextMinute)
					}
				}

				let data = {
					address: chk[i].args['_of'],
					amount: ethers.utils.formatEther(String(finalData.lockAmount)),
					timeperiod: Number(finalData.noOfDays),
					rewardtimeperiod: ethers.utils.formatEther(
						String(finalData.totalclaimedAmount)
					),
					unixdate: Number(chk[i].args['_time']),
					stakingType: chk[i].args['_stakingtype'],
					startTime: stakeStartTIme,
					StakeId: Number(chk[i].args['StakeId']),
					claimed : !claimedSatus,
					claim_enableTime: claim_enableTime
				}

				var rF = parseFloat(value).toFixed(3)
				totalStakedAmount = parseFloat(totalStakedAmount) + parseFloat(rF)
				for (let j = 0; j < unchk.length; j++) {
					var unStakeId = parseInt(unchk[j].args['_stakId'])
					if (lStakeId === unStakeId) {
						totalStakedAmount = parseFloat(totalStakedAmount) - parseFloat(rF)
					}
				}
				data_arr.push(data)
			}
		}
	}

	setDisableClaimbtn && setDisableClaimbtn(false)
	data_arr.sort(function (a, b) {
		return a.claimed - b.claimed
	})
	setStakedAmount && setStakedAmount(totalStakedAmount)
	setLoader && setLoader(false)
	setClaim_Table(data_arr)
}

export const fetchRewardPercent = async (setRewardPercent) => {
	const contract = await readContractFunction('stake');
	const rewardPercentage = await contract._reward()
	setRewardPercent(Number(rewardPercentage))
}

export const onsubmitValidation = async(value, validateFields, setError) => {
	const err = {}
		Object.keys(validateFields).forEach((validate) => {
	    if (!_.isEmpty(value)) {
			if (validateFields[validate].required) {
				if (
					(typeof value[validate] !== 'boolean' &&
						(!value[validate] || value[validate] === '' || !value[validate].length) &&
						validateFields[validate].required)
				) {
					const { errorMessage } = validateFields[validate]
					err[validate] = errorMessage
					setError({ ...err })
					return err
				}
			}
			return err
        }else if(validateFields[validate].required){
            const { errorMessage } = validateFields[validate]
                    err[validate] = errorMessage
                    setError({ ...err })
        }
		})
	
	return err
}

export const stakeUsdao = async (
	date,
	amount,
	setDisableStakeBtn,
	form,
	autoStaked,
	setClaim_Table,
	setAvailablebalance,
	setStakedAmount,
	stakedAmount,
	availablebalance,
	setData
) => {
		const weiAmount = ethers.utils.parseEther(amount)
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const address = await getAddress();
		const StakeContract = await writeContractFunction('stake')
		const UsmContract = await writeContractFunction('usm')
		const stakeContractAddress = await getContractAddress('stake')
		const num = new Date().valueOf()
		const uniqueId = String(num).slice(-7);
		let stakedate,
			transfer,
			allowance = 0
		try {
			allowance = await UsmContract.allowance(address, stakeContractAddress)
			allowance = ethers.utils.formatUnits(allowance, 18)
			if (Number(amount) > Number(allowance)) {
				swal(`Please provide Approval before staking your USDAO with us!`)
				const weiAvailableAmount = ethers.utils.parseEther(availablebalance)
				transfer = await UsmContract.approve(stakeContractAddress, weiAvailableAmount)
			}
		} catch (e) {
			setDisableStakeBtn(false)
			if (e.code === 4001) {
				return swal('User denied transaction.')
			}
		}
		if (transfer) {
			setDisableStakeBtn(true)
			provider
				.waitForTransaction(transfer.hash)
				.then(async (result, error) => {
					if (result.status) {
						swal(
							`Thank you for providing Approval, please confirm the transaction to complete the Staking process.`
						)

						try {
							stakedate = await StakeContract.lock(
								address,
								uniqueId,
								weiAmount,
								date,
								autoStaked
							)
						} catch (e) {
							setDisableStakeBtn(false)
							if (e.code === 4001) {
								return swal('User denied transaction.')
							}
						}
						if (stakedate) {
							provider
								.waitForTransaction(stakedate.hash)
								.then((result, error) => {
									if (result.status) {
										setDisableStakeBtn(false)
										claimTable(setClaim_Table)
										swal(`Staked Successfully!`).then((val) => {
											setStakedAmount(stakedAmount + Number(amount))
											fetchAvailableBalance(setAvailablebalance)
											form.resetFields()
											setData && setData()
										})
									} else {
										setDisableStakeBtn(false)
										swal(`Staked failed please try again!`)
									}
								})
								.catch((e) => {
									setDisableStakeBtn(false)
									swal(`Staked failed please try again!`)
								})
						} else {
							setDisableStakeBtn(false)
							swal(`Staked failed please try again!`)
						}
					} else {
						setDisableStakeBtn(false)
						swal(`Staked failed please try again!`)
					}
				})
		} else {
			try {
				stakedate = await StakeContract.lock(
					address,
					uniqueId,
					weiAmount,
					date,
					autoStaked
				)
			} catch (e) {
				setDisableStakeBtn(false)
				if (e.code === 4001) {
					return swal('User denied transaction.')
				}
			}
			if (stakedate) {
				provider
					.waitForTransaction(stakedate.hash)
					.then((result, error) => {
						if (result.status) {
							setDisableStakeBtn(false)
							claimTable(setClaim_Table)
							swal(`Staked Successfully!`).then((val) => {
								setStakedAmount(stakedAmount + Number(amount))
								fetchAvailableBalance(setAvailablebalance)
								form.resetFields()
								setData && setData()
							})
						} else {
							setDisableStakeBtn(false)
							swal(`Staked failed please try again!`)
						}
					})
					.catch((e) => {
						setDisableStakeBtn(false)
						swal(`Staked failed please try again!`)
					})
			} else {
				setDisableStakeBtn(false)
				swal(`Staked failed please try again!`)
			}
		}
		// setDisableStakeBtn(false)
		return stakedate
}

export const claimUsdao = async (
	StakeId,
	setStakedAmount,
	stakedAmount,
	setDisableClaimbtn,
	setAvailablebalance,
	setClaim_Table
) => {
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		const StakeContract = await writeContractFunction('stake')
		let response;
		const contract = await readContractFunction('stake')
		try {
			var sevendays_Bal = 0
			const chk = await contract.queryFilter('Lock')
			var timestamp = 0
			for (let i = 0; i < chk.length; i++) {
				if (chk[i]) {
					const id = parseInt(chk[i].args['StakeId'])

					var value = ethers.utils.formatEther(String(chk[i].args['_value']))
					var startUnix = String(chk[i].args['_startTime'])
					var days = 7 * 24 * 60 * 60
					var fsevenDays = parseInt(startUnix) + parseInt(days)
					if (id === StakeId) {
						timestamp = timestamp + fsevenDays
					}

					if (startUnix <= timestamp) {
						sevendays_Bal = parseFloat(sevendays_Bal) + parseFloat(value)
					}
				}
			}

			timestamp = 0;
			response = await StakeContract.claim(StakeId)
		} catch (e) {
			console.log(e, 'error in staking !!')  
			setDisableClaimbtn(false)
			if (String(e).includes('Insufficient balance')) {
				return swal(
					'Insufficient Balance! please try after sometime. Thank You!'
				).then((res, err) => {
					if (res)
						claimTable(setClaim_Table, setStakedAmount, setDisableClaimbtn)
				})
			}
			if (String(e).includes('User already rewarded')) {
				return swal('You have been already awarded for this staked amount!')
			}
			if (e.code === 4001) {
				return swal('User denied transaction.')
			}
		}

		if (response) {
			provider.waitForTransaction(response.hash).then(async (res, err) => {
				if (res.status) {
					swal('Claimed Successfully').then((res, err) => {
						if (res) {
							let totalStakedAmount =
								parseFloat(stakedAmount) - parseFloat(value)
							setStakedAmount && setStakedAmount(totalStakedAmount)
							setAvailablebalance &&
								fetchAvailableBalance(setAvailablebalance)
							claimTable(setClaim_Table, setStakedAmount, setDisableClaimbtn)
						}
					})
				} else {
					setDisableClaimbtn(false)
					swal('Please try again')
				}
			})
		}
		return response

}