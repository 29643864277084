import React, {useState, useEffect} from 'react';

import ReactQuill from 'react-quill'

import { targetState, singnatureFunction, callDataInfo, initialExpandedState, loadFees, createProposalFun, proposalValidation, filterSignFunction} from './helper';

const CreateProposalTitle = ({refreshProposal}) =>{
    return(
        <div className='row py-4'>
            <div className='col-md-6 d-flex'>
                <div className='medium-font '>Create &nbsp;</div>
                <div className='medium-font orange-color'>Proposal</div>
            </div>
            <div className='col-md-6 text-right d-flex justify-content-end align-items-center' onClick= {refreshProposal}>
                <div className='caption-content mr-md-3' >Refresh</div>
                <img
                    src={require('../../../assets/images/coolicon.svg')}
                    alt=''
                    className='pointer'
                    style={{ height: '20px' }}
                    // onClick={() => {
                    //     loadFees()
                    //     setProposalForm([
                    //         { ...initialProposalData, id: Math.random().toString() }
                    //     ])
                    // }}
                />
            </div>
        </div>

    )
}

const CreateProposalForm = ({setProposal, proposal, error, setError}) =>{
  
	const [disabled, setDisabled] = useState(false);
	const [callDataInfoVal, setCallDataInfoVal] = useState({});

    const handleInputs = (e) =>{
        const {name, value} = e.target;
		if(name === "target"){
			console.log("delete");
			proposal.signature = "";
		}
        setProposal({...proposal,[name]:value});
		
	}
const singnatureFilter = filterSignFunction(proposal.target)
const createProposal = async () => {
	
		const errorResult = await proposalValidation(proposal, callDataInfoVal.upperLimit);
		const objectLength = Object.keys(errorResult).length

		if(objectLength){
			setError(errorResult);
		}
		else{
			setError({});
		setDisabled(true)
		createProposalFun(proposal, setDisabled);
		}
	}

	useEffect(()=>{
		(async()=>{
			setCallDataInfoVal(await callDataInfo());
		})()
	},[])

    return(
        <div className='proFormWrapper'>
			<p className='label-content'>Proposal Title <span className='text-danger'>*</span></p>
			<input
				type='text'
				className='input-content mb-md-4 mt-md-2 mb-3'
				placeholder='Proposal Title'
				onChange={handleInputs}
				value={proposal.title ? proposal.title : ''}
				name='title'
			/>
			{error.title && (
				<div className='inline-errormsg text-danger mb-mt-4 m-t-20'>
					{error.title}
				</div>
			)}

			<p className='label-content'>Target <span className='text-danger'>*</span></p>
			<select
				name='target'
				id=''
				className='input-content mb-md-4 mt-md-2 mb-3'
				value={proposal.target ? proposal.target : ''}
				onChange={handleInputs}
			>
				{/* <option value='choose_target'>Choose Target</option> */}
				{targetState.map((o) => (
					<option value={o.value} key={o.value}>
						{o.label}
					</option>
				))}
			</select>
			{error.target && (
				<div className='inline-errormsg text-danger mb-md-4'>
					{error.target}
				</div>
			)}

			<div className='row signWrap'>
				<div className='col-md-6'>
					<p className='label-content'>Signature <span className='text-danger'>*</span></p>
					

					<select
						name='signature'
						id=''
						className='input-content mb-md-4 mt-md-2 mb-3'
						value={proposal.signature ? proposal.signature : ''}
						onChange={handleInputs}
					>
						<option value=''>Choose Signature</option>
						{singnatureFilter.map((o) => (
							<option value={o.value} key={o.value}>
								{o.label}
							</option>
						))}
					</select>
					{error.signature && (
						<div className='inline-errormsg text-danger mb-md-4 m-t-20'>
							{error.signature}
						</div>
					)}
				</div>
				<div className='col-md-6'>
					<p className='label-content'>Call Data <span className='text-danger'>*</span></p>
					

					<input
						type='number'
						placeholder={callDataInfoVal[proposal.signature] ? callDataInfoVal[proposal.signature] : `Call Data Value`  }
						className='input-content mb-md-4 mt-md-2 mb-3'
						value={proposal.calldata_value ? proposal.calldata_value : ''}
						name='calldata_value'
						onChange={handleInputs}
						disabled={
							proposal.signature === 'choose_sign' ||
							proposal.signature === 'govFundPool()'
						}
					/>
					{error.calldata_value && (
						<div className='inline-errormsg text-danger mb-md-4 m-t-20'>
							{error.calldata_value}
						</div>
					)}
				</div>
			</div>

			<p className='label-content'>Description</p>
			<ReactQuill value={proposal.description ? proposal.description : ''} name='description' onChange={(value)=>{setProposal({...proposal,['description']:value})}} placeholder= "Enter Description..."/>
		
			<br />
			<div className='row mb-3'>
				<div className='col d-flex'>
					<button
						className='linear-button mr-3'
						disabled={disabled}
						onClick={createProposal}
					>
						Create Proposal
					</button>
					{/* <button
								className='linear-button ml-3'
								onClick={() => setPreviewModal(true)}
							>
								Preview
							</button> */}
				</div>
			</div>
							
		</div>
							

    )
}

const FeeCard = ({fee}) =>{
	const [expandedState, setExpandedState] = useState(initialExpandedState),
	 {burnFee, mintFee, transferFee, transactionFee, defundFee} = fee,
	changeExpandedState = (name, value = true) =>{
		setExpandedState({[name] : value});
	}

    return(
        <>
		<div className='shadow-card px-5 py-4 my-3'>
							<div className='d-flex justify-content-between align-items-center'>
								<div>
									<span className='caption-font'>USDAO Mint: </span>
									<span className='caption-font orange-color'>
										{mintFee && Number(mintFee) * 100 + '%'}
									</span>
								</div>
								{expandedState.uMintFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('uMintFee', false)}
									>
										-
									</p>
								)}
								{!expandedState.uMintFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('uMintFee')}
									>
										+
									</p>
								)}
							</div>
							{expandedState.uMintFee && (
								<p className='pt-3 content-caption'>
									The % of USDAO being deducted for operation purpose.
								</p>
							)}
						</div>
        <div className='shadow-card px-5 py-4 my-3 '>
							<div className='d-flex justify-content-between align-items-center'>
								<div>
									<span className='caption-font'>USDAO Burn: </span>
									<span className='caption-font orange-color'>
										{burnFee && Number(burnFee) * 100 + '%'}
									</span>
								</div>
								{expandedState.uBurnFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('uBurnFee' , false)}
									>
										-
									</p>
								)}
								{!expandedState.uBurnFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('uBurnFee')}
									>
										+
									</p>
								)}
							</div>
							{expandedState.uBurnFee && (
								<p className='pt-3 content-caption'>
									The % of ETH deduction being made while selling USDAO
								</p>
							)}
						</div>
						
						<div className='shadow-card px-5 py-4 my-3'>
							<div className='d-flex justify-content-between align-items-center'>
								<div>
									<span className='caption-font'>USDAO Transfer: </span>
									<span className='caption-font orange-color'>
										{transferFee && Number(transferFee) * 100 + '%'}
									</span>
								</div>
								{expandedState.uTransferFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('uTransferFee', false)}
									>
										-
									</p>
								)}
								{!expandedState.uTransferFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('uTransferFee')}
									>
										+
									</p>
								)}
							</div>
							{expandedState.uTransferFee && (
								<p className='pt-3 content-caption'>
									The % of USDAO deducted while transferring USDAO.
								</p>
							)}
						</div>
						<div className='shadow-card px-5 py-4 my-3'>
							<div className='d-flex justify-content-between align-items-center'>
								<div>
									<span className='caption-font'>ASSET Mint: </span>
									<span className='caption-font orange-color'>
										{transactionFee && Number(transactionFee) * 100 + '%'}
									</span>
								</div>
								{expandedState.aMintFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('aMintFee', false)}
									>
										-
									</p>
								)}
								{!expandedState.aMintFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('aMintFee')}
									>
										+
									</p>
								)}
							</div>
							{expandedState.aMintFee && (
								<p className='pt-3 content-caption'>
									The % of ASSET being deducted for operation purpose.
								</p>
							)}
						</div>
						<div className='shadow-card px-5 py-4 my-3'>
							<div className='d-flex justify-content-between align-items-center'>
								<div>
									<span className='caption-font'>ASSET Burn: </span>
									<span className='caption-font orange-color'>
										{defundFee && Number(defundFee) * 100 + '%'}
									</span>
								</div>
								{expandedState.aBurnFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('aBurnFee', false)}
									>
										-
									</p>
								)}
								{!expandedState.aBurnFee && (
									<p
										className='caption-content'
										onClick={() => changeExpandedState('aBurnFee')}
									>
										+
									</p>
								)}
							</div>
							{expandedState.aBurnFee && (
								<p className='pt-3 content-caption'>
									The % of ETH deduction being made while selling ASSET.
								</p>
							)}
						</div>
        </>
    )
}

const Proposals = () => {
	const [fee, setFee] = useState();
    const [error, setError] = useState({});
	const [proposal, setProposal] = useState({'target':targetState[0].value});
	useEffect(()=>{
		(async()=>{
			const feeData = await loadFees();
			setFee(feeData);
		})()
	}, [])

	const refreshProposal = async() =>{
		const feeData = await loadFees();
		setFee(feeData);
		setError({})
		setProposal({'target':targetState[0].value})
	}

    return(
        <>
            <CreateProposalTitle setProposal={setProposal} proposal={proposal} refreshProposal={refreshProposal}/>
            <div className='row my-3'>
					<div className='col-md-8 lock-height  p-r'>
                      <CreateProposalForm  setProposal={setProposal} setError={setError} proposal={proposal} error={error}/>
                    </div>
                    <div className='col-md-4 lock-height toggle-card propFeeWrapper'>
					{fee &&	<FeeCard fee = {fee}/> }
                    </div>
            </div>        
        </>
    )
}

export {Proposals}