import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers'
import { NavBar } from '../pages/common/Header';
import { readContractFunction, writeContractFunction } from '../blockchain/tradingSdk';
import LeftNav from './leftnav';

const RevenueAdmin = () =>{
    const [data, setData] = useState({});
    useEffect(()=>{
        (async()=>{
            const revenueContract = await writeContractFunction('revenue');
            // window.usmContract = revenueContract;
             console.log("revenueContract", revenueContract);

            const ethBal = ethers.utils.formatEther(await revenueContract.getEthBalance());
            const assetBal = ethers.utils.formatEther(await revenueContract.getAssetReserve("0x89663989051406300b69Ed15fE6944F9aB71De50"));

            const usdaoBal = ethers.utils.formatEther(await revenueContract.getUSDAOReserve());
            setData({...data,ethBal:Number(ethBal),assetBal,usdaoBal});
        })()
       

    }, [])
    console.log("data", data)
    return(
        <>
        <NavBar />
        <LeftNav />
        <div className='row revenueWrapper'>
            <div className='col-md-6'>
                <div className='left-text'>
                    <h2>Admin Revenue</h2>
                    <p>The balances related to the Revenue collected by the USDAO Protocol in ETH, USDAO and ASSET tokens.</p>
                </div>
            </div>
            <div className='col-md-6'>
                {/* <div className='revenue-form-wrapper'>
                    <p>Admin Revenue</p>
                    <label>ETH Balance</label>
                    <input placeholder='ETH Balance' value={data.ethBal}/>

                    <label>Asset Balance</label>
                    <input placeholder='Asset Balance' value={data.assetBal}/>

                    <label>USDAO Balance</label>
                    <input placeholder='USDAO Balance' value={data.usdaoBal}/>
                    <div className='buttonWrapper'>
                        <button>Submit</button>
                    </div>
                    
                </div> */}

                <div className='revenue-form-wrapper'>
                    <p>Admin Revenue</p>
                    <label>ETH Balance</label>
                    <p>{data.ethBal}</p>

                    <label>Asset Balance</label>
                    <p>{data.assetBal}</p>


                    <label>USDAO Balance</label>
                    <p>{data.usdaoBal}</p>

                    {/* <div className='buttonWrapper'>
                        <button>Submit</button>
                    </div> */}
                    
                </div>
            </div>
        </div>
        </>
    )
}

export default RevenueAdmin;