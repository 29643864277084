import swal from "sweetalert";
import axios from "axios";
import web3 from 'web3';

import { ethers } from "ethers";
import {
  readContractFunction,
  balanceOf,
  getAddress,
  writeContractFunction,
} from "../../blockchain/tradingSdk";
import { truncateToDecimals } from "../common/util";
import { contractDetails } from "../../blockchain/contractDetails";

const initialExpandedState = {
  uBurnFee: false,
  uMintFee: true,
  uTransferFee: false,
  aMintFee: false,
  aBurnFee: false,
};

let provider = null;
if (typeof window.ethereum !== "undefined") {
  provider = new ethers.providers.Web3Provider(window.ethereum);
}

const loadFees = async () => {
  // return
  const contract = await readContractFunction("usm");
  if (contract) {
    const burnFee = new Promise(async (resolve, reject) => {
      try {
        const data = await contract.USDAOBurnFee();

        resolve(ethers.utils.formatEther(data));
      } catch (error) {
        reject(error);
      }
    });
    const mintFee = new Promise(async (resolve, reject) => {
      try {
        const data = await contract.USDAOMintFee();
        resolve(ethers.utils.formatEther(data));
      } catch (error) {
        reject(error);
      }
    });
    const transferFee = new Promise(async (resolve, reject) => {
      try {
        const data = await contract.USDAOTransferFee();
        resolve(ethers.utils.formatEther(data));
      } catch (error) {
        reject(error);
      }
    });
    const transactionFee = new Promise(async (resolve, reject) => {
      try {
        // const data = await contract.fundFee()
        const data = await contract.ASSETMintFee();
        resolve(ethers.utils.formatEther(data));
        // resolve(5)
      } catch (error) {
        reject(error);
      }
    });
    const defundFee = new Promise(async (resolve, reject) => {
      try {
        const data = await contract.ASSETBurnFee();
        resolve(ethers.utils.formatEther(data));
      } catch (error) {
        reject(error);
      }
    });

    const result = await Promise.all([
      burnFee,
      mintFee,
      transferFee,
      transactionFee,
      defundFee,
    ]);
    const fetchedFees = {
      burnFee: result[0],
      mintFee: result[1],
      transferFee: result[2],
      transactionFee: result[3],
      defundFee: result[4],
    };
    //setFees(fetchedFees)
    return fetchedFees;
  }
};

const getVotes = async () => {
  const yourVote = await balanceOf("comp");
  const contract = await readContractFunction("comp");

  const delegatedVote = await contract.getCurrentVotes(await getAddress());
  const delegatedVotes = Number(ethers.utils.formatEther(delegatedVote));
  const divider = Number(await contract.totalSupply());

  return {
    yourVote,
    delegatedVotes,
    divider,
  };
};

const setDelegate = async(delegateTo, setDisabledDeligate, setDelegateTo, setVotes) => {
 
    const CompContract = await writeContractFunction('comp');
    try {
        if (delegateTo) {
            const delegateToaddress = ethers.utils.getAddress(delegateTo)

            let delegatecomp = await CompContract.delegate(delegateToaddress)
            if (delegatecomp) {
                setDisabledDeligate(true)
                swal(`Please wait as your request is being processed.`)
                provider
                    .waitForTransaction(delegatecomp.hash)
                    .then(async(result, error) => {
                        setDisabledDeligate(false)
                        if (result && result.status) {
                           const votes =  await getVotes();
                            setVotes(votes)
                            setDelegateTo('')
                            swal(`Successfully Delegated ${delegateTo} ! `)
                        } else {
                            
                            swal(`Something went wrong, Please try again`)
                        }
                    })
            }
        } else {
            swal(`Please provide valid address`)
        }
    } catch (error) {
        console.log("error", error)
        if (error.code === 4001) {
            swal(`User denied transaction.`)
            }
        else{
            swal(`Something went wrong, Please try again `)
            }
    }
};

const targetState = [
  {
    label: "USM Contract",
    value: "0x4d48Cfca1975b1e3AF1E69B44C6b0BfDF5dc8286",
  },
  {
    label: "Revenue Contract",
    value: "0x948294EB790645b6e8Ac4E103A6c96865729158c",
  },
];

const singnatureFunction = [
  {
    label: "Change USDAO Mint",
    value: "changeMintFee(uint8,uint32)",
    targetState: "USM Contract",
  },
  {
    label: "Change USDAO Burn",
    value: "changeBurnFee(uint8,uint32)",
    targetState: "USM Contract",
  },

  {
    label: "Change Transfer",
    value: "changeTransferFee(uint8,uint32)",
    targetState: "USM Contract",
  },
  {
    label: "Change Asset Mint",
    value: "changeFundFee(uint8,uint32)",
    targetState: "USM Contract",
  },
  {
    label: "Change Asset Burn",
    value: "changeDefundFee(uint8,uint32)",
    targetState: "USM Contract",
  },
  {
    label: "Ether Reserve Transfer",
    value: "etherReserveTransfer(address,uint256)",
    targetState: "Revenue Contract",
  },
  {
    label: "USDAO Reserve Transfer",
    value: "usdaoReserveTransfer(address,uint256)",
    targetState: "Revenue Contract",
  },
  {
    label: "ASSET Reserve Transfer",
    value:
      "assetReserveTransfer(address,uint256)",
    targetState: "Revenue Contract",
  },
  {
    label: "Foundation Limit Change",
    value: "changeFoundationWidthdrawalLimt(uint8,uint32)",
    targetState: "Revenue Contract",
  },
  // { label: 'Gov Fund Pool', value: 'govFundPool()' },
  // { label: 'Gov Defund Pool', value: 'govDefundPool(uint256)' }
];

const filterSignFunction = (
  value = "0x4d48Cfca1975b1e3AF1E69B44C6b0BfDF5dc8286"
) => {
  const stateObj = targetState.filter((item) => item.value === value);
  const stateLabel = stateObj[0]["label"];
  const filterSign = singnatureFunction.filter(
    (item) => item.targetState === stateLabel
  );
  return filterSign;
};
const revenueMaxLimit = async() =>{
  const revenueContract = await readContractFunction('revenue'),
        ethBal = ethers.utils.formatEther(await revenueContract.getEthBalance()),
        usdaoReserve = ethers.utils.formatEther(await revenueContract.getUSDAOReserve()),
        assetReserveBal = ethers.utils.formatEther(await revenueContract.getAssetReserve("0x54E249B4311b68c3355Dd44C138aaD33eFCDaa30"));
        return {
          ethBal:truncateToDecimals(ethBal, 4), usdaoReserve: truncateToDecimals(usdaoReserve, 4), assetReserveBal:truncateToDecimals(assetReserveBal, 4)
        }

}




const callDataInfo =async()=>{
  const upperLimit =  await revenueMaxLimit()
  
  return {
  "changeMintFee(uint8,uint32)": "0.1 < Value ≤ 0.5",
  "changeBurnFee(uint8,uint32)": "0.1 < Value ≤ 0.5",
  "changeDefundFee(uint8,uint32)": "0.1 < Value ≤ 10",
  "changeTransferFee(uint8,uint32)": "0.1 < Value ≤ 0.5",
  "changeFundFee(uint8,uint32)": "0.1 < Value ≤ 10",
  "etherReserveTransfer(address,uint256)": `1 < Value < ${truncateToDecimals(upperLimit.ethBal, 4)}`,
  "usdaoReserveTransfer(address,uint256)": `1 < Value < ${truncateToDecimals(upperLimit.usdaoReserve, 4)}`,
  "assetReserveTransfer(address,uint256)": `1 < Value < ${truncateToDecimals(upperLimit.assetReserveBal, 4)}`,
  "changeFoundationWidthdrawalLimt(uint8,uint32)": "1 < Value < 15",
  upperLimit: upperLimit
}
};
const signatureValidation = [
  { name: "changeMintFee(uint8,uint32)", inputs: ["uint8", "uint32"] },
  { name: "changeBurnFee(uint8,uint32)", inputs: ["uint8", "uint32"] },
  { name: "changeDefundFee(uint8,uint32)", inputs: ["uint8", "uint32"] },
  { name: "changeTransferFee(uint8,uint32)", inputs: ["uint8", "uint32"] },
  {
    name: "changeFundFee(uint8,uint32)",
    inputs: ["uint8", "uint32"],
  },
  { name: "govFundPool()", inputs: [] },
  { name: "govDefundPool(uint256)", inputs: ["uint256"] },
  { name: "etherReserveTransfer(address,uint256)", inputs: ["address", "uint"] },
  { name: "usdaoReserveTransfer(address,uint256)", inputs: ["address", "uint"] },
  { name: "assetReserveTransfer(address,uint256)", inputs: ["address", "uint"] },
  { name: "changeFoundationWidthdrawalLimt(uint8,uint32)", inputs: ["uint8", "uint32"] },
];

const encodeParameters = (types, values) => {
  const abi = new ethers.utils.AbiCoder();
  return abi.encode(types, values);
};

const decimalToWhole = async (value) => {
  let index = 100;
  const splitted = value.split(".");
  if (splitted[1]) {
    for (let i = 1; i <= splitted[1].length; i++) {
      index = index * 10;
    }
  }
  return [splitted[1] ? (value * index) / 100 : value, index];
};

const convertByte = (data) =>{
  const abi = new ethers.utils.AbiCoder();
  return abi.encodePacked(data)
}

const createProposalFun = async (proposalData, setDisabled) => {
  const usmContract = await writeContractFunction("usm");
  
  const foundationAddrss = await usmContract.foundationAddress();
  const revenueContract = await usmContract.revenueContract();
  const GovContract = await writeContractFunction("governorAlpha");
  let param_type, param_values;
  if (proposalData.signature !== "govFundPool()") {
    const filterSignvalid = signatureValidation.find(
      (s) => s.name === proposalData.signature
    );
    param_type = filterSignvalid ? filterSignvalid.inputs : '';
   
    if(proposalData.signature === "etherReserveTransfer(address,uint256)" || proposalData.signature === "usdaoReserveTransfer(address,uint256)"){
      const paramInpurtValue = ethers.utils.parseEther(proposalData.calldata_value)
      param_values = [foundationAddrss, paramInpurtValue]
    }
    else if(proposalData.signature === "assetReserveTransfer(address,uint256)"){
      const paramInpurtValue = ethers.utils.parseEther(proposalData.calldata_value)
      param_values = [foundationAddrss, paramInpurtValue]
    }
    else{
      param_values = await decimalToWhole(proposalData.calldata_value);
    }
    
  }
  try {

    //data prepare
    let targets = [proposalData.target];
    const values = [0];
    const signatures = [proposalData.signature];
    //const callData = ["0x000000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000003e8"];
    let callData = [encodeParameters(param_type, param_values)];
    if(proposalData.target === "0x948294EB790645b6e8Ac4E103A6c96865729158c"){
     
      targets = [revenueContract];

    }
    const titles = proposalData.title;

    //console.log("targets", targets, "values", values,"signatures", signatures, "callData", callData, "titles", titles )
    const propResult = await GovContract.propose(
      targets,
      values,
      signatures,
      callData,
      titles
    );
    if (propResult) {
      setDisabled(true);
      swal(`Please wait as your request is being processed.`);
      const result = await provider.waitForTransaction(propResult.hash);
      setDisabled(false);
      if (result.status) {
        swal(`Proposal Created Successfully!`);
        let { logs = [] } = result,
          data = logs[0].data,
          id = data.substring(2, 66);
        id = parseInt(id, 16);
        await axios
          .post(`${process.env.REACT_APP_BACKEND_URL}governance/create-desc`, {
            description: proposalData.description,
            desc_id: id,
            startDate: "",
            endDate: "",
          })
          .then((data) => {
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          });
      }
    }
  }catch(e){
        setDisabled(false)
        console.log("error", e);
        if (String(e).includes('found an already active proposal')) {
            return swal(`You cannot create a new proposal as your previous proposal is still in an Active state.`)
        }
        if (
            String(e).includes(
                'Error Occured ! cannot estimate gas; transaction may fail or may require manual gas limit (error={"code":-32603,'
            )
        ) {
            return swal(`Something went wrong!`)
        }
        if (String(e).includes('proposer votes below proposal threshold')) {
            return swal(
                `User dosen’t have enough tokens delegated to create a proposal`
            )
        }
        if (e.code === 4001) {
            return swal(`User denied Transactions.`)
        } else {
            return swal(`Error Occured ! Execution Failed`)
        }
    }
   
  
  
};

const proposalValidation = async(proposalData, upperLimit) => {
   const { signature, calldata_value, title, target } = proposalData,
    errorObj = {};

  if (!signature) {
    errorObj["signature"] = "Signature is mandatory field!";
  }
  if (!calldata_value) {
    errorObj["calldata_value"] = "Call Data is mandatory field!";
  } else {
    const val = Number(calldata_value);
   if(contractDetails['revenue'].address['4'] === target){
     if(signature === 'changeFoundationWidthdrawalLimt(uint8,uint32)' && (val > 15 ||  val < 1 || val === 1 || val === 15)){
      errorObj["calldata_value"] = `Value should be greater than 1 and less than 15`;

     }
     
    else if(signature === "assetReserveTransfer(address,uint256)" && (val > upperLimit.assetReserveBal || val == upperLimit.assetReserveBal || val < 1 || val === 1)) {
      errorObj["calldata_value"] = `Value should be greater than 1 and less than ${upperLimit.assetReserveBal}`;
    }
    else if(signature === "usdaoReserveTransfer(address,uint256)" && (val > upperLimit.usdaoReserve || val == upperLimit.usdaoReserve ||  val < 1 || val === 1)) {
      errorObj["calldata_value"] = `Value should be greater than 1 and less than ${upperLimit.usdaoReserve}`;
    }
    else if(signature === "etherReserveTransfer(address,uint256)" && (val > upperLimit.ethBal || val == upperLimit.ethBal || val < 1 || val === 1)) {
      errorObj["calldata_value"] = `Value should be greater than 1 and less than ${upperLimit.ethBal}`;
    }
   }
    else if (
      signature === "changeFundFee(uint8,uint32)" ||
      signature === "changeDefundFee(uint8,uint32)"
    ) {
      if (val < 0.1 || val > 10 || val === 0.1) {
        errorObj["calldata_value"] = `Value should be greater than 0.1 and less than or equal to 10`;
      }
    } else {

      if ((val > 0.5 || val < 0.1 || val === 0.1) && target !== "0x948294EB790645b6e8Ac4E103A6c96865729158c") {
        errorObj[
          "calldata_value"
        ] = `Value should be greater than 0.1 and less than or equal to 0.5`;
      }
    }
  }

  if (!title) {
    errorObj["title"] = "Proposal Title is mandatory";
  }
  return errorObj;
};

export {
  loadFees,
  getVotes,
  setDelegate,
  targetState,
  singnatureFunction,
  callDataInfo,
  initialExpandedState,
  createProposalFun,
  proposalValidation,
  filterSignFunction,
};
