import { ethers } from 'ethers'
import web3 from 'web3';
import Swal from 'sweetalert2';
import { contractDetails } from './contractDetails';
import { decimalPlaces } from '../pages/common/util';
import { getAccoundDetails } from './commonFunction';
//old infura link https://goerli.infura.io/v3/b001cbdee80c4e52806e2e072e601ce4
const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_INFURA_LINK)

const getMetaMask = () => {
	const providerMetaMask = new ethers.providers.Web3Provider(window.ethereum)
	return providerMetaMask
}

const getChainId = async () => {
	// const network = await provider.getNetwork()
	// return network.chainId
	let chainId = await window.ethereum.request({ method: 'net_version' });
	return chainId
}
const getNetworkName = async () => {
	const accountDetails = await getAccoundDetails();
	return accountDetails.networkType
}

const readContractFunction = async (token) => {
	const chainId = await getChainId()
	const provider = await getMetaMask()
	return new ethers.Contract(
		contractDetails[token].address[chainId],
		contractDetails[token].abi,
		provider
	)
}

const writeContractFunction = async (token) => {
	const mProviderInner = getMetaMask()
	const signer = await mProviderInner.getSigner()
	const chainId = await getChainId()
	return new ethers.Contract(
		contractDetails[token].address[chainId],
		contractDetails[token].abi,
		signer
	)
}

const getContractAddress = async (token) => {
	const chainId = await getChainId()
	return contractDetails[token].address[chainId]
}

const writeContractPrivate = async (token) => {
	let privateKey = localStorage.getItem('userPrivateKey')
	const signer = new ethers.Wallet(privateKey)
	const account = signer.connect(provider)
	const chainId = await getChainId()
	return new ethers.Contract(
		contractDetails[token].address[chainId],
		contractDetails[token].abi,
		account
	)
}
const getAddress = async () => {
	var myWeb3 = new web3(window.ethereum) // window.web3.currentProvider
	const account = await myWeb3.eth.getAccounts()
	return account[0]
},
	balanceOf = async (token) => {
		const readFunction = await readContractFunction(token)
		const balance = await readFunction.balanceOf(await getAddress())
		const decimal = await readFunction.decimals()
		const formatedBalance = ethers.utils.formatUnits(balance, decimal)
		return parseFloat(formatedBalance)
	},
	balanceOfAddress = async (token, address) => {
		const readFunction = await readContractFunction(token)
		const balance = await readFunction.balanceOf(address)
		const decimal = await readFunction.decimals()
		const formatedBalance = ethers.utils.formatUnits(balance, decimal)

		return parseFloat(formatedBalance)
	},
	getEtherBalance = async () => {

		return new Promise(async (resolve, reject) => {
			const network = await getNetworkName() // use rinkeby testnet
			const provider = ethers.getDefaultProvider(network);
			const address = await getAddress();
			provider.getBalance(address).then((balance) => {
				const balanceInEth = ethers.utils.formatEther(balance)
				resolve(balanceInEth);
			})
		})
	}, verifyTransaction = (hash, token, refreshAllCards) => {
		Swal.fire({
			title: 'Sent to Blockchain',
			html: `<p>Waiting for Blockchain Confirmation...</p>
			<p><a target='_blank' href='https://goerli.etherscan.io/tx/${hash}'>View On Ether Scan</a> </p>`
		})
		// check transaction
		const mProviderInner = getMetaMask()
		mProviderInner.waitForTransaction(hash).then(async (result) => {
			if (result.status) {
				refreshAllCards()
				Swal.fire({
					icon: 'success',
					html: `<p>Transaction Successful</p>
			<p><a target='_blank' href='https://goerli.etherscan.io/tx/${hash}'>View On Ether Scan</a> </p>`
				})

				if (token) {
					const { totalSupply } = await readContractFunction(token)
					return decimalPlaces(ethers.utils.formatEther(await totalSupply()), 4)
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					html: `<p>Transaction failed!</p>
			<p><a target='_blank' href='https://goerli.etherscan.io/tx/${hash}'>View On Ether Scan</a> </p>`
				})
			}
		})
	}

	; (async () => {
		if (typeof window.ethereum !== 'undefined') {
			let currentAdd = await getAddress()

			setInterval(async () => {
				const currentAddress = await getAddress()
				if (currentAddress !== currentAdd) {
					window.location.reload()
				}
			}, 3000)
		}
	})()

const loginMetaMaskFn = async () => {
	try {

		let provider = null
		if (typeof window.ethereum !== 'undefined') {
			provider = new ethers.providers.Web3Provider(window.ethereum)
		}
		const signer = await provider.getSigner()

		const address = await signer.getAddress()
		if (address) {
			return true
		}
	} catch (error) {
		return false
	}
}


const loginMetaMask = async () => {
	try {

		let provider = null
		if (typeof window.ethereum !== 'undefined') {
			provider = new ethers.providers.Web3Provider(window.ethereum)
		}
		const signer = await provider.getSigner()

		const address = await signer.getAddress()
		if (address) {
			return true
		}
	} catch (error) {
		Swal.fire({
			icon: 'error',
			showConfirmButton: false,
			allowOutsideClick: false,
			className: "remove-click",
			html: `Please log in to Metamask first. If not installed, please follow below link to install it.</br>` +

				`<a target='_blank' href="https://metamask.io/download/">https://metamask.io/download</a>`
		})
		return false
	}
}

const addCustomTokenMetamask = async (token) => {
	const chainId = await getChainId()
	const tokenAddress = contractDetails[token].address[chainId];
	const tokenSymbol = token === 'usm' ? 'USDAO' : token;
	const tokenDecimals = 18;
	const tokenImage = 'https://usdao.io/static/media/stable-coin.88a3d2a14d839faf59fda4d2ec296ad1.svg';

	try {
		// wasAdded is a boolean. Like any RPC method, an error may be thrown.
		const wasAdded = await window.ethereum.request({
			method: 'wallet_watchAsset',
			params: {
				type: 'ERC20', // Initially only supports ERC20, but eventually more!
				options: {
					address: tokenAddress, // The address that the token is at.
					symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
					decimals: tokenDecimals, // The number of decimals in the token
					image: tokenImage, // A string url of the token logo
				},
			},
		});

		if (wasAdded) {
			console.log('Thanks for your interest!');
		} else {
			console.log('Your loss!');
		}
	} catch (error) {
		console.log(error);
	}
}
//connecMetamaskWallet()
export {
	readContractFunction,
	writeContractFunction,
	getAddress,
	balanceOf,
	balanceOfAddress,
	writeContractPrivate,
	getContractAddress,
	getMetaMask,
	getEtherBalance,
	verifyTransaction,
	loginMetaMask,
	getChainId,
	loginMetaMaskFn,
	provider,
	addCustomTokenMetamask
}
