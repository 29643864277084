import { ethers } from 'ethers';
import { getCoingeckoPrice, toPercentage, truncateToDecimals } from '../common/util';
import {fetchStaked} from '../stake/helper';
import axios from 'axios';
import {readContractFunction, writeContractFunction, getAddress, balanceOf, getEtherBalance, getMetaMask, verifyTransaction} from '../../blockchain/tradingSdk'
import swal from 'sweetalert';
import Swal from 'sweetalert2';

export const fetchInitialWeb3Data = async() => {
         const usmBalance = await balanceOf('usm')
         const assetBalance = await balanceOf('fum')
         const etherBalance = await getEtherBalance()
         return {usmBalance, assetBalance, etherBalance}
}

export const oneUsdaoValue = (marketPrice) =>{
	return 1/marketPrice;
}

export const oneAssetValue = async() =>{
	let val = await setEthToUsmOne1('1');
	return 1/val
}

export const expectedOutput = (val, fee) =>{
	return val - (val * (fee/100))
}

export const expectedUsdaoOutput = async(val) =>{
	
	const response = await axios.get(
		'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
	)
	const usd = response.data.ethereum.usd
	return val * usd
}

export const slippageFn = (value, actualVal) =>{
	 return (actualVal - value)/actualVal
}

export const networkFee = (val, fee) =>{
	return val*fee
}

export const networkFeeForSell = async(val, fee) =>{
	const usmContract = await readContractFunction('usm');
	const marketPrice = await marketPricePromise(usmContract);
	return (val*fee) * marketPrice
}


export const networkFeeAssetBuy = async(val, fee) =>{
	const oneAssetVal = await oneAssetValue();
	const usmContract = await readContractFunction('usm');
	const marketPrice = await marketPricePromise(usmContract);
	const assetValueInDollar = oneAssetVal * marketPrice;
	return (val*(fee)) * assetValueInDollar
} 

const stakedPromise = new Promise(async (resolve, reject) => {
	const stakedBalance = await fetchStaked()
	resolve(stakedBalance)
})

const debtPromise = async(usmViewContract) => {
		const ratio = await usmViewContract.debtRatio()
		const debtRatio = ethers.utils.formatEther(ratio)
        return debtRatio
}

const marketPricePromise = async(usmContract) => {
		const marketPrice = await usmContract.latestPrice()
	    return Number(marketPrice) / 10e17
}


const totalBufferPromise = async(usmViewContract) => {
		const coingecko = await getCoingeckoPrice()
		const ethBuffer = await usmViewContract.ethBuffer(false)
		const formattedBuffer = ethers.utils.formatEther(ethBuffer)
		const totalBuffer = formattedBuffer * coingecko;
		return totalBuffer
}

const totalCollateralPromise = async(usmContract) => {
		const collateral = await usmContract.ethPool()
		const coingecko = await getCoingeckoPrice()
		const formattedCollateral = ethers.utils.formatEther(collateral)
		const totalCollateral = formattedCollateral * coingecko
		return totalCollateral
}

const totalStaked = async(stabilityContract) => {
	const address = await getAddress()
	const usdaoStaked = await stabilityContract.getCompoundedUSDAODeposit(address)
	const usdaoStakedVal = ethers.utils.formatEther(usdaoStaked)
	return usdaoStakedVal
}


export const fetchAllInitialValues = async () => {
	const usmContract = await readContractFunction('usm')
	const usmViewContract = await readContractFunction('usmView')
	// const stabilityContract = await readContractFunction('stabilityPool')

	const pricePromises = await Promise.all([
		stakedPromise,
        debtPromise(usmViewContract),
        marketPricePromise(usmContract),
		totalBufferPromise(usmViewContract),
		totalCollateralPromise(usmContract),
		// totalStaked(stabilityContract)
	])

	return {
		prices: {
			stakedBalance: pricePromises[0],
			debtRatio: pricePromises[1],
			marketPrice: pricePromises[2],
			totalBuffer: pricePromises[3],
			totalCollateral: pricePromises[4],
			// totalStake: pricePromises[5]
		}
	}
}

export const setEthToUsmOne1 = async (_amount) => {
    const usmviewContract = await readContractFunction('usmView')
	const weiAmount = ethers.utils.parseEther(String(_amount))
	await window.ethereum.enable()

	const data = await usmviewContract.fumFund(weiAmount)
	if (data) {
		const usmBalance = ethers.utils.formatUnits(data['fumOut'], 18)
		return usmBalance
	} else {
	}
}

export const setUsmToEthOne1 = async (_amount) => {
    const usmviewContract = await readContractFunction('usmView')
	const weiAmount = ethers.utils.parseEther(String(_amount))
	await window.ethereum.enable()

	const data = await usmviewContract.fumDeFund(weiAmount)
	if (data) {
		const usmBalance = ethers.utils.formatUnits(data['ethOut'], 18)
		return usmBalance
	}
}

export const setEthToUsm1 = async (_amount) => {
    const usmviewContract = await readContractFunction('usmView')
	const weiAmount = ethers.utils.parseEther(String(_amount))
	await window.ethereum.enable()

	const data1 = await usmviewContract.usmMint(weiAmount)
	if (data1) {
		const usmBalance1 = ethers.utils.formatEther(data1['usmOut'], 18)
		const usmViewBalance = usmBalance1 === 0.0 ? 0 : usmBalance1
		return usmViewBalance
	}
}

export const setUsmToEth1 = async(_amount) => {
    const usmviewContract = await readContractFunction('usmView')
	if (_amount === 0) {
		return 0
	}
	const weiAmount = ethers.utils.parseEther(String(_amount))
	await window.ethereum.enable()

	const data = await usmviewContract.usmBurn(weiAmount)
	if (data) {
		const usmBalance = ethers.utils.formatUnits(data['ethOut'], 18)
		const ETHViewBalance = usmBalance === 0.0 ? 0 : usmBalance
		return ETHViewBalance
	}
}

export const buyUSM = async (amount, balance, refreshAllCards) => {
	if(Number(amount) >= truncateToDecimals(Number(balance), 2)){
		swal(`You cannot sell more than ${String(Number(truncateToDecimals(balance, 6)) - 0.01)} ETH. Kindly use MAX.`)
		return
	}else if(!Number(amount)){
		swal("Enter valid amount")
		return
	}

	const usm = await writeContractFunction('usm')

	Swal.fire({
		title: 'Confirm',
		text: 'Waiting for Metamask Confirmation..'
	})

	const mProvider = getMetaMask()

	const weiAmount = ethers.utils.parseEther(amount)
	const address = await getAddress()

	const gasLimit = await usm.estimateGas.mint(address, 0, { value: weiAmount })
	const gasPrice = await mProvider.getGasPrice()
	const sendtransaction = (gasPrice, gasLimit) => {
		let currentGasLimit = String(Number(gasLimit))
		usm
			.mint(address, 0, {
				value: weiAmount,
				gasPrice: gasPrice,
				gasLimit: Number(currentGasLimit) + 1000
			})
			.then((data) => {
				data && data.hash && verifyTransaction(data.hash, 'usm', refreshAllCards)
			})
			.catch((error) => {
				if (error.code === 4001) {
					return swal('User denied transaction.')
				}else{
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'Something went wrong!'
					})
					if (error.code === -32603) {
						const gasIncreaseBy = Math.pow(10, currentGasLimit.length - 2)
						currentGasLimit = Number(currentGasLimit) + gasIncreaseBy
						sendtransaction(gasPrice, currentGasLimit)
					}
				}
				
				// dispatch(metamaskError(error))
			})
	}
	sendtransaction(gasPrice, gasLimit)
}

export const sellUsm = async (amount, balance, refreshAllCards) => {
	const usm = await writeContractFunction('usm')
	if(Number(amount) > Number(balance)){
		swal("You don't have sufficient balance.")
		return
	}else if(!Number(amount)){
		swal("Enter valid amount")
		return
	} else {
		Swal.fire({
			title: 'Confirm',
			text: 'Waiting for Metamask Confirmation..'
		})
		const mProvider = getMetaMask()
		const weiAmount = ethers.utils.parseEther(String(amount))
		const address = await getAddress()
		const gasLimit = await usm.estimateGas.burn(address, weiAmount, 0)
		const gasPrice = await mProvider.getGasPrice();
		const sendtransaction = (gasPrice, gasLimit) => {
			let currentGasLimit = String(Number(gasLimit))
			usm
				.burn(address, weiAmount, 0)
				.then((data) => {
					data && data.hash && verifyTransaction(data.hash, 'usm', refreshAllCards)
				})
				.catch((error) => {
					if (error.code === 4001) {
						return swal('User denied transaction.')
					}else{
						console.log("error", error)
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!'
						})
						if (error.code === -32603) {
							console.log("faild due to gas")
							const gasIncreaseBy = Math.pow(10, currentGasLimit.length - 2)
							currentGasLimit = Number(currentGasLimit) + gasIncreaseBy
							sendtransaction(gasPrice, currentGasLimit)
						}
					}
					
				})
		}
		sendtransaction(gasPrice, gasLimit)
	}
}

export const buyFum = async (amount, balance, refreshAllCards) => {
	if (Number(amount) >= truncateToDecimals(Number(balance), 2)) {
		swal(`You cannot sell more than ${String(Number(truncateToDecimals(balance, 6)) - 0.01)} ETH. Kindly use MAX.`)
	} else if(!Number(amount)){
		swal("Enter valid amount")
		return
	} else {
		// buy fum logic
		Swal.fire({
			title: 'Confirm',
			text: 'Waiting for Metamask Confirmation..'
		})
		const mProvider = getMetaMask()
		const weiAmount = ethers.utils.parseEther(amount)
		const address = await getAddress()
		const usm = await writeContractFunction('usm')
		const gasLimit = await usm.estimateGas.fund(address, 0, {
			value: weiAmount
		})
		const gasPrice = await mProvider.getGasPrice()

		const sendtransaction = (gasPrice, gasLimit) => {
			let currentGasLimit = String(Number(gasLimit))
			usm
				.fund(address, 0, {
					value: weiAmount,
					gasPrice: gasPrice,
					gasLimit: currentGasLimit
				})
				.then((data) => {
					data && data.hash && verifyTransaction(data.hash, 'fum', refreshAllCards)
				})
				.catch((error) => {
					if (error.code === 4001) {
						return swal('User denied transaction.')
					}else{
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: 'Something went wrong!'
						})
						if (error.code === -32603) {
							const gasIncreaseBy = Math.pow(10, currentGasLimit.length - 2)
							currentGasLimit = Number(currentGasLimit) + gasIncreaseBy
							sendtransaction(gasPrice, currentGasLimit)
						}
					}
					
				})
		}
		sendtransaction(gasPrice, gasLimit)
	}
}

export const sellFum = async (amount, balance, refreshAllCards) => {
	if (Number(balance) < 0.03 || Number(balance) == 0.03) {
		swal("You don't have sufficient balance")
		return false
	}
	if (!amount || Number(amount) === 0) {
		swal(`Please Enter ASSET Amount.`)
			return false
	}
	if (Number(amount) >= truncateToDecimals(Number(balance))) {
		swal(`You can not sell more than ${String(truncateToDecimals(Number(balance), 2) - 0.03)} ASSET. Kindly use MAX.`)
	} else if (Number(amount) === Number(balance)) {
		swal('You can sell max amount only')
	} else {
		const mProvider = getMetaMask(),
			usmViewContract = await readContractFunction('usmView'),
			ratio = await usmViewContract.debtRatio(),
			formattedRatio = ethers.utils.formatEther(ratio),
			debtRatio = toPercentage(formattedRatio)
		if (Number(debtRatio) > 80) {
			swal('Debt Ratio is too high, please try again after sometime.')
			return
		}
		Swal.fire({
			title: 'Confirm',
			text: 'Waiting for Metamask Confirmation..'
		})
		try {
			const weiAmount = ethers.utils.parseEther(amount)
			const address = await getAddress()
			const usm = await writeContractFunction('usm')
			const gasLimit = await usm.estimateGas.defund(address, weiAmount, 0)
			const gasPrice = await mProvider.getGasPrice()

			const sendtransaction = (gasPrice, gasLimit) => {
				let currentGasLimit = String(Number(gasLimit))
				usm
					.defund(address, weiAmount, 0, {
						gasPrice: gasPrice,
						gasLimit: currentGasLimit
					})
					.then((data) => {
						data && data.hash && verifyTransaction(data.hash, 'fum', refreshAllCards)
					})
					.catch((error) => {
						if (error.code === 4001) {
							return swal('User denied transaction.')
						}else{
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: 'Something went wrong!'
							})
							if (error.code === -32603) {
								const gasIncreaseBy = Math.pow(10, currentGasLimit.length - 2)
								currentGasLimit = Number(currentGasLimit) + gasIncreaseBy
								sendtransaction(gasPrice, currentGasLimit)
							}
						}
						
					})
			}
			sendtransaction(gasPrice, gasLimit)
		} catch (error) {
			swal(
				error.message.indexOf('Not allowed during prefund') !== -1
					? 'Not allowed during prefund'
					: 'Something went wrong.'
			)
		}
	}
}

export const usdao_reverse_change = async (eValue, buyingState, setUsdaoInput) =>{
	const usdao_input = eValue;
	if (buyingState.usdaoBuy) {
		
		const ethOut = await setUsmToEth1(eValue);

		setUsdaoInput({
			ethBuyVal: ethOut,
			usdaoBuyVal: usdao_input
		})
		
		//setUsdaoInput({ ...usdaoInput, usdaoBuyVal: e.target.value })
		//setUsdaoInput({ ...usdaoInput, usdao: e.target.value })
	} else {
		const usmOut = await setEthToUsmOne1(eValue);
		setUsdaoInput({
			ethSellVal: usdao_input,
			usdaoSellVal: usmOut
		})
	}
}

export const getUSDAOMintFee = async(usmOut, burn = false) =>{
	const usmContract = await readContractFunction('usm')
	const weiAmount = ethers.utils.parseEther(String(usmOut))
	await window.ethereum.enable();
	let data;
	if(burn){
		data = await usmContract.getUSDAOBurnFee(weiAmount)
	}
	else{
		data = await usmContract.getUSDAOMintFee(weiAmount)

	}

	if (data) {
		const mintFee = ethers.utils.formatUnits(data, 18)
		return mintFee
	}
}

export const getAssetFee = async(usmOut, burn = false) =>{
	const usmContract = await readContractFunction('usm')
	const weiAmount = ethers.utils.parseEther(String(usmOut))
	await window.ethereum.enable();
	let data;
	if(burn){
		data = await usmContract.getAssetBurnFee(weiAmount)
	}
	else{
		data = await usmContract.getAssetMintFee(weiAmount)

	}

	if (data) {
		const mintFee = ethers.utils.formatUnits(data, 18)
		return mintFee
	}
}