import React,{useState} from 'react'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { Tooltip, Popover, Button } from 'antd';

const PopOver = ({title, content}) =>{

	return(
		<Popover placement="bottomRight" content={content}  title={title} trigger="hover" style={{ width: 1000 }}
		>
      		<InfoIcon
			
			style={{
				cursor: 'pointer',
				marginRight: '6px',
				marginLeft: '6px',
				marginTop: '-9px',
				fontSize: '15px'
			}}
		/>
    	</Popover>
	)
}

const PopoverComp = ({ content }) => {
    return (
		<>
		<Tooltip placement="topLeft" title={content}>
		<InfoIcon
			
				style={{
					cursor: 'pointer',
					marginRight: '6px',
					marginLeft: '6px',
					marginTop: '-9px',
					fontSize: '15px'
				}}
			/>
		</Tooltip>
		

		
		</>
	)
}

export { 
	PopoverComp,
	PopOver  
}
