import React, { useEffect, useState } from 'react';
import { decimalPlaces } from '../common/util'
import { contractDetails } from "../../blockchain/contractDetails"
import { BigNumber, ethers } from 'ethers';
import Swal from "sweetalert2"
import { Oval } from "react-loader-spinner";
import { orange } from '@material-ui/core/colors';

const LeftSideCard = (props) => {

    // useEffect(() => {
    //     loadBlockchainData();
    // }, [])

    useEffect(() => {
        window.ethereum.on('accountsChanged', async function (accounts) {
            window.location.reload()
        })
    }, [])


    let { prices, price, bal, usmBal, fumBal, totalColl, buffer, debtR, stakedAmt} = props;
    return (
        <>
            <div className='col-lg-6'>
                <div className='card-content'>
                    <div className='row'>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>ETH Price</p>
                                <p className='bold-font-value'>
                                    {price != null ? "$ " + decimalPlaces(price) : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>ETH Balance</p>
                                <p className='bold-font-value'>
                                    {bal != null ? decimalPlaces(bal) : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* {prices.etherBalance ? decimalPlaces(prices.etherBalance) : '0.00'} */}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>Total Collateral</p>
                                <p className='bold-font-value'>
                                    {totalColl != null ? "$ " + decimalPlaces(totalColl) : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* $ {prices.totalCollateral ? decimalPlaces(prices.totalCollateral) : '0.00'} */}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>USDAO Balance</p>
                                <p className='bold-font-value'>
                                    {usmBal != null ? decimalPlaces(usmBal) : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* {prices.usmBalance ? decimalPlaces(prices.usmBalance) : '0.00'} */}
                                </p>
                            </div>
                        </div>

                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>Buffer</p>
                                <p className='bold-font-value'>
                                    {buffer != null ? "$ " + decimalPlaces(buffer) : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* $ {prices.totalBuffer ? decimalPlaces(prices.totalBuffer) : '0.00'} */}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>ASSET Balance</p>
                                <p className='bold-font-value'>
                                    {fumBal != null ? decimalPlaces(fumBal) : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* {prices.assetBalance ? decimalPlaces(prices.assetBalance) : '0.00'} */}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>Debt Ratio</p>
                                <p className='bold-font-value'>
                                    {debtR != null ? decimalPlaces(debtR) + " %" : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* {prices.debtRatio ? decimalPlaces(Number(prices.debtRatio) * 100) : '0.00'} % */}
                                </p>
                            </div>
                        </div>
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                            <div className='card-design'>
                                <p>Staked on OnVault</p>
                                <p className='bold-font-value'>
                                    {stakedAmt != null ? decimalPlaces(stakedAmt) + " USDAO" : <div style={{ paddingLeft: "115px" }}>
                                        <Oval
                                            color="#F85E11"
                                            secondaryColor="#FF5733"
                                            height={30}
                                            width={30}
                                        />
                                    </div>}
                                    {/* {prices.stakedBalance ? decimalPlaces(prices.stakedBalance) : '0.00'} USDAO */}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default LeftSideCard;