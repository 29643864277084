import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { PopOver } from '../../util/utilComponent';
import { decimalPlaces, truncateToDecimals } from '../common/util';
import { contractDetails } from "../../blockchain/contractDetails"
import { loadFees } from '../governance/helper';
import {
    fetchInitialWeb3Data,
    fetchAllInitialValues,
    setEthToUsmOne1,
    setEthToUsm1,
    setUsmToEthOne1,
    setUsmToEth1,
    buyUSM,
    sellUsm,
    buyFum,
    sellFum,
    oneUsdaoValue,
    expectedOutput,
    expectedUsdaoOutput,
    oneAssetValue,
    slippageFn,
    networkFeeAssetBuy,
    networkFee,
    networkFeeForSell,
    usdao_reverse_change,
    getUSDAOMintFee,
    getAssetFee
} from '../home/helper'
import { addCustomTokenMetamask } from '../../blockchain/tradingSdk';
import { ethers, BigNumber } from 'ethers';
import Swal from "sweetalert2"




const initialBuyingValues = {
    usdaoBuy: true,
    assetBuy: true,
    sendUsdao: true
}

const initialUsdaoInputVal = {
    usdaoBuyVal: '',
    ethBuyVal: '',
    usdaoSellVal: '',
    ethSellVal: '',
}

const initialAssetInputVal = {
    assetBuyVal: '',
    ethBuyVal: '',
    assetSellVal: '',
    ethSellVal: '',
}

const initialCalculatedValForUsdao = {
    expectOutputBuy: 0,
    slippageBuy: 0,
    networkFeeBuy: 0,
    expectOutputSell: 0,
    slippageSell: 0,
    networkFeeSell: 0
}

const initialCalculatedValForAsset = {
    expectOutputBuy: 0,
    slippageBuy: 0,
    networkFeeBuy: 0,
    expectOutputSell: 0,
    slippageSell: 0,
    networkFeeSell: 0
}

const contentForAsset = (calculation, type, buyingState) => (
    <div className='hover-card'>
        <div className='d-flex card-content'>
            <div className="text-left">Expected Output</div>
            <div className="text-right" >{buyingState.usdaoBuy ? truncateToDecimals(calculation.expectOutputBuy, 6) : truncateToDecimals(calculation.expectOutputSell, 6)} {`${'Asset'}`}</div>
        </div>
        {/* <div className='d-flex card-content'>
                <div>Price Impact</div>
                <div >-0.06%</div>
            </div> */}
        <hr className='seperate-line'></hr>
        <div className='d-flex card-content'>
            <div className="text-left">Slippage </div>
            <div className={cn("text-right", calculation.slippageBuy > 1 && 'yellow', calculation.slippageBuy > 5 && 'orange', calculation.slippageBuy > 10 && 'red', calculation.slippageBuy < 1 && 'green')}>{buyingState.usdaoBuy ? truncateToDecimals(calculation.slippageBuy, 6) : truncateToDecimals(calculation.slippageSell, 6)} %</div>
        </div>
        <div className='d-flex card-content'>
            <div className="text-left">Network Fee</div>
            <div className="text-right" >≈ ${buyingState.usdaoBuy ? truncateToDecimals(calculation.networkFeeBuy, 6) : truncateToDecimals(calculation.networkFeeSell, 3)}</div>
        </div>
    </div>
);


const contentForUsdao = (calculation, type, buyingState) => (
    <div className='hover-card'>
        <div className='d-flex card-content'>
            <div className="text-left">Expected Output</div>
            <div className="text-right" >{buyingState.usdaoBuy ? truncateToDecimals(calculation.expectOutputBuy, 6) : truncateToDecimals(calculation.expectOutputSell, 6)} {`${'USDAO'}`}</div>
        </div>
        {/* <div className='d-flex card-content'>
            <div>Price Impact</div>
            <div >-0.06%</div>
        </div> */}
        <hr className='seperate-line'></hr>
        <div className='d-flex card-content'>
            <div className="text-left">Slippage </div>
            <div className={cn("text-right", calculation.slippageBuy === 0 && 'white', calculation.slippageBuy > 1 && 'yellow', calculation.slippageBuy > 5 && 'orange', calculation.slippageBuy > 10 && 'red', calculation.slippageBuy < 1 && calculation.slippageBuy !== 0 && 'green')}>{buyingState.usdaoBuy ? truncateToDecimals(calculation.slippageBuy, 6) : truncateToDecimals(calculation.slippageSell, 6)} %</div>
        </div>
        <div className='d-flex card-content'>
            <div className="text-left">Network Fee</div>
            <div className="text-right" >≈ ${buyingState.usdaoBuy ? truncateToDecimals(calculation.networkFeeBuy, 6) : truncateToDecimals(calculation.networkFeeSell, 3)}</div>
        </div>
    </div>
);



const RightSideCard = (props) => {

    let { prices, setPrices, refreshBlockChainData } = props;

    const [dollarState, setDollarState] = useState(0)
    const [activeTab, setActiveTab] = useState('usdao');
    const [buyingState, setBuyingState] = useState(initialBuyingValues);
    const [usdaoInput, setUsdaoInput] = useState(initialUsdaoInputVal);
    const [assetInput, setAssetInput] = useState(initialAssetInputVal);
    const [calculationForUsdao, setCalculationUsdao] = useState(initialCalculatedValForUsdao);
    const [calculationForAsset, setCalculationForAsset] = useState(initialCalculatedValForAsset);
    const [oneAssetVal, setOneAssetVal] = useState();

    const changeBuyingState = (key, state = true) =>
        setBuyingState({ ...buyingState, [key]: state })


    const refreshAllCards = async () => {
        refreshBlockChainData()
        const web3Values = await fetchInitialWeb3Data()
        const values = await fetchAllInitialValues()
        setPrices({ ...values.prices, ...web3Values })
    }

    useEffect(() => {
        (async function () {
            let oneAsset = await oneAssetValue()
            setOneAssetVal(oneAsset)
        })();

    }, [])

    useEffect(() => {
        getDollarValue()
    }, [assetInput.eth])

    const getDollarValue = async () => {

        if (assetInput.eth && !buyingState.assetBuy) {
            const value1 = await setUsmToEthOne1(assetInput.eth)

            setDollarState(value1 * prices.marketPrice)
        }
    }

    const assetChangeHandler = (e) => {
        ; (async () => {
            const value = e;
            let value1 = '';
            let assetFee;

            if (!value) {
                setAssetInput(initialAssetInputVal)
            }
            if (buyingState.assetBuy) {
                setAssetInput({
                    ...assetInput,
                    ethBuyVal: value
                })
            } else {
                setAssetInput({
                    ...assetInput,
                    assetSellVal: value
                })
            }

            if (value && buyingState.assetBuy) {
                value1 = await setEthToUsmOne1(value)
                assetFee = await getAssetFee(value1);
            } else {
                value1 = await setUsmToEthOne1(value);
                assetFee = await getAssetFee(value1, true);
            }
            if (buyingState.assetBuy) {
                setAssetInput((state) => ({
                    ...state,
                    assetBuyVal: truncateToDecimals(value1, 6)
                }))
                let { transactionFee } = await loadFees(),
                    expectedOutputVal = Number(value1) + Number(assetFee), //await expectedOutput(Number(value1), Number(transactionFee)),
                    dollar = getDollarValueFn(value),
                    oneFumVal = await setEthToUsmOne1(1),
                    actualFumVal = value * oneFumVal,
                    slippageVal = await slippageFn(Number(value1), actualFumVal),
                    networkFeeVal = await networkFeeAssetBuy(expectedOutputVal, Number(transactionFee));
                setCalculationForAsset({
                    ...calculationForAsset,
                    expectOutputBuy: expectedOutputVal,
                    slippageBuy: slippageVal,
                    networkFeeBuy: networkFeeVal
                })
            } else {
                let { defundFee } = await loadFees(),
                    expectedOutputVal = Number(value1) + Number(assetFee), //await expectedOutput(Number(value1), Number(defundFee)),
                    // slippageVal = await slippageAssetSell(Number(value1), dollar),
                    oneEthVal = await setUsmToEthOne1(1),
                    actualEthVal = value * oneEthVal,
                    slippageVal = await slippageFn(Number(value1), actualEthVal),
                    networkFeeVal = await networkFeeForSell(expectedOutputVal, Number(defundFee));
                setCalculationForAsset({
                    ...calculationForAsset,
                    expectOutputSell: expectedOutputVal,
                    slippageSell: slippageVal,
                    networkFeeSell: networkFeeVal
                })
                setAssetInput((state) => ({
                    ...state,
                    ethSellVal: truncateToDecimals(value1, 6)
                }))
            }
        })()
    }

    const getDollarValueFn = (val) => {
        return Number(val) * Number(prices.marketPrice)
    }
    const [searchTerm, setSearchTerm] = useState('');
    const [assetInputNum, setAssetInputNum] = useState('');
    const [usdaoUsdaoInput, setUsdaoUsdaoInput] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.value) {
                usadoChangeHandler(searchTerm.value);
            }
            else {
                setUsdaoInput(initialUsdaoInputVal)
                setCalculationUsdao(initialCalculatedValForUsdao)
            }


        }, 1000)

        if (buyingState.usdaoBuy) {
            setUsdaoInput({
                ethBuyVal: searchTerm.value,
                usdaoBuyVal: ''
            })
        } else {
            setUsdaoInput({
                ethSellVal: '',
                usdaoSellVal: searchTerm.value
            })
        }

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (assetInputNum.value) {
                assetChangeHandler(assetInputNum.value);
            }
            else {
                setAssetInput(initialAssetInputVal)
                setCalculationForAsset(initialCalculatedValForAsset)
            }


        }, 1000)

        if (buyingState.assetBuy) {
            setAssetInput({
                ...assetInput,
                ethBuyVal: assetInputNum.value
            })
        } else {
            setAssetInput({
                ...assetInput,
                assetSellVal: assetInputNum.value
            })
        }



        return () => clearTimeout(delayDebounceFn)
    }, [assetInputNum])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (usdaoUsdaoInput.value) {
                //  assetChangeHandler(assetInputNum.value);
                usdao_reverse_change(usdaoUsdaoInput.value, buyingState, setUsdaoInput);
            }
            else {
                setUsdaoInput(initialUsdaoInputVal)
                // setCalculationUsdao(initialCalculatedValForUsdao)
            }


        }, 1000)

        if (buyingState.usdaoBuy) {
            setUsdaoInput({
                ...usdaoInput,
                usdaoBuyVal: usdaoUsdaoInput.value,
            })
        } else {
            setUsdaoInput({
                ethSellVal: usdaoUsdaoInput.value,
                usdaoSellVal: ''
            })
            // setAssetInput({
            //     ...assetInput,
            //     ethSellVal: usdaoUsdaoInput.value
            // })
        }



        return () => clearTimeout(delayDebounceFn)
    }, [usdaoUsdaoInput])

    const usadoChangeHandler = (e) => {
        ; (async () => {
            let value = e;
            let value1 = '';

            if (!value) {

                setUsdaoInput(initialUsdaoInputVal)
            }

            if (buyingState.usdaoBuy) {
                setUsdaoInput({
                    ethBuyVal: value,
                    usdaoBuyVal: ''
                })
            } else {
                setUsdaoInput({
                    ethSellVal: '',
                    usdaoSellVal: value
                })
            }
            if (buyingState.usdaoBuy) {
                value1 = await setEthToUsm1(value)
                // console.log(1, value1);
                const usdaoMintFee = await getUSDAOMintFee(value1);

                setUsdaoInput((state) => ({
                    ...state,
                    usdaoBuyVal: truncateToDecimals(value1, 6)
                }))
                let { mintFee } = await loadFees()
                let expectedOutputVal = Number(value1) + Number(usdaoMintFee); // await expectedUsdaoOutput(Number(value), Number(mintFee))
                let oneUsmVal = await setEthToUsm1(1)
                let actualUsmVal = value * oneUsmVal
                let slippageVal = await slippageFn(Number(value1), actualUsmVal)
                let networkFeeVal = await networkFee(expectedOutputVal, Number(mintFee))
                // setCalculationUsdao({
                //     ...calculationForUsdao,
                //     expectOutputBuy: expectedOutputVal,
                //     slippageBuy: slippageVal,
                //     networkFeeBuy: networkFeeVal
                // })
            } else {
                value1 = await setUsmToEth1(value)
                // { ...usdaoInput, usdao: value }
                setUsdaoInput((state) => ({
                    ...state,
                    ethSellVal: truncateToDecimals(value1, 6)
                }))
                const usdaoBurnFee = await getUSDAOMintFee(value1, true);
                let { burnFee } = await loadFees(),
                    expectedOutputVal = Number(value1) + Number(usdaoBurnFee), // await expectedOutput(Number(value1), Number(burnFee)),
                    networkFeeVal = await networkFeeForSell(expectedOutputVal, Number(burnFee)),
                    oneEthVal = await setUsmToEth1(1),
                    actualEthVal = value * oneEthVal,
                    slippageVal = await slippageFn(Number(value1), actualEthVal);
                // setCalculationUsdao({
                //     ...calculationForUsdao,
                //     expectOutputSell: expectedOutputVal,
                //     slippageSell: slippageVal,
                //     networkFeeSell: networkFeeVal
                // })
            }
        })()
    }

    const usdaoMaxHandler = async () => {
        if (buyingState.usdaoBuy) {
            const value = prices.etherBalance
            let value1 = ''
            setUsdaoInput({
                ...assetInput,
                ethBuyVal: value > 0.01 ? String(Number(truncateToDecimals(value, 6)) - 0.01) : 0
            })
            if (value) {
                value1 = await setEthToUsm1(value)
            }

            setUsdaoInput((state) => ({
                ...state,
                usdaoBuyVal: truncateToDecimals(value1, 6)
            }))
            let { mintFee } = await loadFees()
            let expectedOutputVal = await expectedUsdaoOutput(Number(value), Number(mintFee))
            let oneUsmVal = await setEthToUsm1(1)
            let actualUsmVal = value * oneUsmVal
            let slippageVal = await slippageFn(Number(value1), actualUsmVal)
            let networkFeeVal = await networkFee(value1, Number(mintFee))
            // setCalculationUsdao({
            //     ...calculationForUsdao,
            //     expectOutputBuy: expectedOutputVal,
            //     slippageBuy: slippageVal,
            //     networkFeeBuy: networkFeeVal
            // })
        } else {
            const value = prices.usmBalance
            let value1 = ''
            setUsdaoInput({
                ...assetInput,
                usdaoSellVal: truncateToDecimals(value, 6)
            })
            if (value) {
                value1 = await setUsmToEth1(value)
            }

            setUsdaoInput((state) => ({
                ...state,
                ethSellVal: truncateToDecimals(value1, 6)
            }))
            let { burnFee } = await loadFees(),
                expectedOutputVal = await expectedOutput(Number(value1), Number(burnFee)),
                networkFeeVal = await networkFeeForSell(value1, Number(burnFee)),
                oneEthVal = await setUsmToEth1(1),
                actualEthVal = value * oneEthVal,
                slippageVal = await slippageFn(Number(value1), actualEthVal);
            // setCalculationUsdao({
            //     ...calculationForUsdao,
            //     expectOutputSell: expectedOutputVal,
            //     slippageSell: slippageVal,
            //     networkFeeSell: networkFeeVal
            // })
        }
    }
    const maxHandler = async () => {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        const _address = await signer.getAddress();
        if (buyingState.usdaoBuy) {
            let balance = await provider.getBalance(_address);
            let e = { target: { value: Number(ethers.utils.formatEther(balance)) - 0.01 } };
            await handleInput(e);
        } else {
            let _contractUSM = new ethers.Contract(contractDetails.usm.address["5"], contractDetails.usm.abi, provider);
            let usmBalance = await _contractUSM.balanceOf(_address);
            let e = { target: { value: ethers.utils.formatEther(usmBalance) } };
            await handleInput(e);
        }
    }
    const [usdVal, setUsdVal] = useState(null);

    const assestMaxHandler = async () => {
        if (buyingState.assetBuy) {
            let value = prices.etherBalance;
            value = value > 0.01 ? String(Number(truncateToDecimals(value, 6)) - 0.01) : 0
            const value1 = await setEthToUsmOne1(value)
            setAssetInput({
                ethBuyVal: value,
                assetBuyVal: truncateToDecimals(value1, 6)
            })
            let { transactionFee } = await loadFees(),
                expectedOutputVal = await expectedOutput(Number(value1), Number(transactionFee)),
                dollar = getDollarValueFn(value),
                oneFumVal = await setEthToUsmOne1(1),
                actualFumVal = value * oneFumVal,
                slippageVal = await slippageFn(Number(value1), actualFumVal),
                networkFeeVal = await networkFeeAssetBuy(value1, Number(transactionFee));
            setCalculationForAsset({
                ...calculationForAsset,
                expectOutputBuy: expectedOutputVal,
                slippageBuy: slippageVal,
                networkFeeBuy: networkFeeVal
            })
        } else {
            let value = prices.assetBalance
            value = value > 0.03 ? String(Number(truncateToDecimals(value, 6)) - 0.03) : 0
            const value1 = await setUsmToEthOne1(value)
            setAssetInput({
                ethSellVal: truncateToDecimals(value1, 6),
                assetSellVal: value
            })
            let { defundFee } = await loadFees(),
                expectedOutputVal = await expectedOutput(Number(value1), Number(defundFee)),
                dollar = getDollarValueFn(value),
                // slippageVal = await slippageAssetSell(Number(value1), dollar),
                oneEthVal = await setUsmToEthOne1(1),
                actualEthVal = value * oneEthVal,
                slippageVal = await slippageFn(Number(value1), actualEthVal),
                networkFeeVal = await networkFeeForSell(value1, Number(defundFee));
            setCalculationForAsset({
                ...calculationForAsset,
                expectOutputSell: expectedOutputVal,
                slippageSell: slippageVal,
                networkFeeSell: networkFeeVal
            })
        }
    }

    const usdaoBuySell = async () => {
        if (notAllowed == true) {
            Swal.fire({
                icon: 'error',
                html: `<p>Buying/Selling is disabled during the Prefund stage!</p>`
            })
        } else {
            if (buyingState.usdaoBuy) {
                buyUSM(usdaoInput.ethBuyVal, prices.etherBalance, refreshAllCards)
                    .then(() => {
                        refreshAllCards()
                    })
                setTimeout(() => {
                    refreshAllCards()
                }, 3000)
            } else {
                await sellUsm(
                    usdaoInput.usdaoSellVal,
                    prices.usmBalance,
                    refreshAllCards
                )
                setTimeout(() => {
                    refreshAllCards()
                }, 3000)
            }
        }
    }

    const assestBuySell = async () => {

        if (buyingState.assetBuy) {
            await buyFum(
                assetInput.ethBuyVal,
                prices.etherBalance,
                refreshAllCards
            )
            setTimeout(() => {
                refreshAllCards()
            }, 3000)
        } else {
            await sellFum(assetInput.assetSellVal, prices.assetBalance, refreshAllCards)
            setTimeout(() => {
                refreshAllCards()
            }, 3000)
        }
    }

    const onClickAccordian = () => {
        var accordions = document.getElementsByClassName("accordion");
        for (var i = 0; i < accordions.length; i++) {
            accordions[i].onclick = function () {
                this.classList.toggle('is-open');

                var content = this.nextElementSibling;
                if (content.style.maxHeight) {
                    // accordion is currently open, so close it
                    content.style.maxHeight = null;
                } else {
                    let height = Number(content.scrollHeight) + 5
                    // accordion is currently closed, so open it
                    content.style.maxHeight = height + "px";
                }
            }
        }
    }
    const [notAllowed, setNotAllowed] = useState(false);
    useEffect(() => {
        loadBlockchainData();
    }, [])

    useEffect(() => {
        window.ethereum.on('accountsChanged', async function (accounts) {
            window.location.reload()
        })
    }, [])

    const [expOut, setExpectedOutput] = useState(0);
    const [mintFees, setMintFees] = useState(0)
    const [burnFees, setBurnFees] = useState(0)

    const loadBlockchainData = async () => {
        let provider = new ethers.providers.Web3Provider(window.ethereum);
        let _contractUSM = new ethers.Contract(contractDetails.usm.address["5"], contractDetails.usm.abi, provider);
        setUsm(_contractUSM);
        let _contractUSMView = new ethers.Contract(contractDetails.usmView.address["5"], contractDetails.usmView.abi, provider);
        let ethPool = await _contractUSM.ethPool();
        if (ethPool.eq(BigNumber.from("0")))
            setNotAllowed(true);
        else
            setNotAllowed(false);

        setUsmView(_contractUSMView);
        let p = await _contractUSM.latestPrice();
        setPrice(ethers.utils.formatEther(p))
        let mintFee = await _contractUSM.USDAOMintFee();
        mintFee = ethers.utils.formatEther(mintFee);
        setMintFees(mintFee);
        let burnFee = await _contractUSM.USDAOBurnFee();
        burnFee = ethers.utils.formatEther(burnFee);
        setBurnFees(burnFee);
    }
    const [usmView, setUsmView] = useState()
    const [usm, setUsm] = useState()
    const [price, setPrice] = useState(null)
    const [output, setOutput] = useState(null)
    const [feeOutBuy, setFeeOutBuy] = useState(0)
    const [feeOutSell, setFeeOutSell] = useState(0)
    const [slippage, setSlippage] = useState(0)


    const handleInput = async (e) => {
        if (e.target.value == '') {
            setOutput(null)
            setExpectedOutput(0)
            setFeeOutBuy(0)
            setFeeOutSell(0)
            setSlippage(0)
            setUsdVal(null)
        }
        let v1 = e.target.value;
        if (buyingState.usdaoBuy == true) {
            setUsdVal(v1 * price);
            setSearchTerm({ value: e.target.value, isEth: buyingState.usdaoBuy })
            let v = ethers.utils.parseEther(e.target.value);
            let output = await usmView.usmMint(v);
            setOutput(ethers.utils.formatEther(output.usmOut)) //output.usmOut
            // let oneUsmVal = await usmView.usmMint(ethers.utils.parseEther("1"));
            // oneUsmVal = ethers.utils.formatEther(oneUsmVal.usmOut);
            let expected = v1 * price;
            let actualVal = ethers.utils.formatEther(output.usmOut);
            setExpectedOutput(expected);
            let slippageVal = (expected - actualVal) / expected;
            slippageVal *= 100;
            // if (slippageVal > 0)
            setSlippage(slippageVal)
            // else
            //     setSlippage(0)
            let m = await usm.getUSDAOMintFee(output.usmOut);
            let e1 = ethers.utils.formatEther(output.usmOut.add(m));
            // setExpectedOutput(e1);
            let x = e1 * mintFees;
            setFeeOutBuy(x);
            setCalculationUsdao({
                expectOutputBuy: expected,
                slippageBuy: slippageVal,
                networkFeeBuy: 0,
                expectOutputSell: 0,
                slippageSell: 0,
                networkFeeSell: 0
            });
        } else {
            setSearchTerm({ value: e.target.value, isEth: buyingState.usdaoBuy })
            let output = await usmView.usmBurn(ethers.utils.parseEther(e.target.value));
            setOutput(ethers.utils.formatEther(output.ethOut))
            let oneEthVal = await usmView.usmBurn(ethers.utils.parseEther("1"));
            oneEthVal = ethers.utils.formatEther(oneEthVal.ethOut);
            let actualEthVal = v1 / price;
            setExpectedOutput(actualEthVal);
            let val1 = ethers.utils.formatEther(output.ethOut);
            let slippageVal = (actualEthVal - val1) / actualEthVal;
            slippageVal *= 100;
            // if (slippageVal > 0)
            setSlippage(slippageVal)
            // else
            //     setSlippage(0)
            let b = await usm.getUSDAOMintFee(output.ethOut);
            let e1 = ethers.utils.formatEther(output.ethOut.add(b));
            let x = e1 * burnFees * price;
            setFeeOutSell(x.toString());
            setCalculationUsdao({
                expectOutputBuy: 0,
                slippageBuy: 0,
                networkFeeBuy: 0,
                expectOutputSell: actualEthVal,
                slippageSell: slippageVal,
                networkFeeSell: x.toString()
            });
        }
    }
    const handleInputUSD = async (e) => {
        let val = e.target.value;
        setUsdVal(val);
        let toPass = val / price;
        let e1 = { target: { value: toPass.toString() } };
        await handleInput(e1);
    }
    useEffect(() => {
        setOutput(null)
        setExpectedOutput(0)
        setFeeOutBuy(0)
        setFeeOutSell(0)
        setSlippage(0)
        setUsdVal(null)
    }, [buyingState.usdaoBuy])

    return (
        <>
            <div className='col-lg-6 d-flex align-items-center tradingWrapper'>
                {/* <div className='col-md-12 pl-0 pr-0'>
                    <div className='tabWrapper'>
                        <button className={activeTab === "usdao" ? 'active' : ''}
                            onClick={() => {
                                setActiveTab('usdao')
                                setUsdaoInput(initialUsdaoInputVal)
                                setCalculationForAsset(initialCalculatedValForAsset)
                                setCalculationUsdao(initialCalculatedValForUsdao)

                            }
                            }> USDAO</button>
                        <button className={activeTab === "asset" ? 'active' : 'hidden'}
                            onClick={() => {
                                setActiveTab('asset')
                                setAssetInput(initialAssetInputVal)
                                setCalculationForAsset(initialCalculatedValForAsset)
                                setCalculationUsdao(initialCalculatedValForUsdao)

                            }
                            }>ASSET</button>
                    </div>
                </div> */}

                <div className='calculation-area w-100 row'>
                    {activeTab === "usdao" &&
                        <div className='col-md-12 justify-content-center align-items-center'>
                            <div className='calculation-card text-center px-4'>
                                <div className='row text-center'>
                                    <div className='col'>
                                        <p className='calc-title'>USDAO</p>
                                    </div>
                                </div>
                                <div className='buy-sell-part row my-4'>
                                    <div
                                        className={
                                            buyingState.usdaoBuy
                                                ? 'col-6 text-center buy-sell-orange pointer'
                                                : 'col-6 text-center buy-sell-white pointer'
                                        }
                                        onClick={() => {
                                            changeBuyingState('usdaoBuy')
                                            setUsdaoInput({ usdaoBuyVal: '', ethBuyVal: '' })
                                            setCalculationForAsset(initialCalculatedValForAsset)
                                            // setCalculationUsdao(initialCalculatedValForUsdao)
                                        }}
                                    >
                                        BUY
                                    </div>
                                    <div
                                        className={
                                            buyingState.usdaoBuy
                                                ? 'col-6 text-center buy-sell-white pointer'
                                                : 'col-6 text-center buy-sell-orange pointer'
                                        }
                                        onClick={() => {
                                            changeBuyingState('usdaoBuy', false)
                                            setUsdaoInput({ usdaoSellVal: '', ethSellVal: '' })
                                            setCalculationForAsset(initialCalculatedValForAsset)
                                            // setCalculationUsdao(initialCalculatedValForUsdao)
                                        }}
                                    >
                                        SELL
                                    </div>
                                </div>
                                <div className='input-content'>
                                    <input
                                        type='number'
                                        placeholder='0.0000'
                                        className={`enable ${buyingState.usdaoBuy ? 'buy' : 'sell'}`}
                                        value={
                                            buyingState.usdaoBuy ? usdaoInput.ethBuyVal : usdaoInput.usdaoSellVal
                                        }
                                        // onChange={(e) => setSearchTerm({ value: e.target.value, isEth: buyingState.usdaoBuy })}
                                        onChange={(e) => handleInput(e)}
                                        title=""
                                    />
                                    <button
                                        className='linear-button-1 pointer max-btn'
                                        // onClick={usdaoMaxHandler}
                                        onClick={maxHandler}
                                    >
                                        MAX
                                    </button>
                                    <button className='linear-button-orange pointer'>
                                        {buyingState.usdaoBuy ? 'ETH' : 'USDAO'}
                                    </button>
                                </div>
                                {/* Notice: For USD Calculations */}
                                {/* {buyingState.usdaoBuy ?
                                    <div className='input-content'>
                                        <input
                                            type='number'
                                            placeholder='0.0000'
                                            value={usdVal}
                                            onChange={(e) => handleInputUSD(e)}
                                            title=""
                                        />
                                        <button className='linear-button-orange pointer'>
                                            USD
                                        </button>
                                    </div> :
                                    <></>
                                } */}
                                <div className='input-content '>
                                    <input
                                        title=""
                                        placeholder='0.00000'
                                        disabled
                                        value={
                                            // buyingState.usdaoBuy ? usdaoInput.usdaoBuyVal : usdaoInput.ethSellVal
                                            output != null ? output : '0.0'
                                        }
                                        onChange={(e) => {
                                            setUsdaoUsdaoInput({ value: e.target.value, isUsdao: buyingState.usdaoBuy })
                                            // usdao_reverse_change(e, buyingState, setUsdaoInput);
                                        }}
                                    />

                                    <button className='linear-button-orange pointer'>
                                        {buyingState.usdaoBuy ? 'USDAO' : 'ETH'}
                                    </button>
                                </div>
                                <div className='transaction-details'>

                                    <div className='trasaction-bar accordion' onClick={onClickAccordian} >
                                        <PopOver content={contentForUsdao(calculationForUsdao, 'usdao', buyingState)} />
                                        1 USDAO = {price != null ? truncateToDecimals(oneUsdaoValue(price), 6) : '0.00'}  ETH
                                        {/* 1 USDAO = {prices.marketPrice ? truncateToDecimals(oneUsdaoValue(prices.marketPrice), 6) : '0.00'}  ETH */}

                                    </div>
                                    <div className='accordion-content app-accordian'>
                                        <div className="card" >
                                            <div className="card-body">
                                                <div className='d-flex card-content'>
                                                    <div className="text-left">Expected Output</div>
                                                    <div className="text-right" >{truncateToDecimals(expOut, 6)} {buyingState.usdaoBuy ? 'USDAO' : 'ETH'}</div>
                                                    {/* <div className="text-right" >{truncateToDecimals(buyingState.usdaoBuy ? calculationForUsdao.expectOutputBuy : calculationForUsdao.expectOutputSell, 6)} {buyingState.usdaoBuy ? 'USDAO' : 'ETH'}</div> */}
                                                </div>
                                                {/* <div className='d-flex card-content'>
                                            <div>Price Impact</div>
                                            <div >-0.06%</div>
                                        </div> */}
                                                <hr className='seperate-line'></hr>
                                                <div className='d-flex card-content'>
                                                    <div className="text-left">Slippage </div>
                                                    <div className={cn("text-right", slippage === 0 && 'white', slippage > 1 && 'yellow', slippage > 5 && 'orange', slippage > 10 && 'red', slippage < 1 && slippage !== 0 && 'green')}>{slippage ? truncateToDecimals(slippage, 6) : 0} %</div>
                                                </div>
                                                <div className='d-flex card-content'>
                                                    <div className="text-left">{buyingState.usdaoBuy ? 'Mint' : 'Burn'} Fee</div>
                                                    <div className="text-right" >≈ ${truncateToDecimals(buyingState.usdaoBuy ? feeOutBuy : feeOutSell, 3)}</div>
                                                    {/* <div className="text-right" >≈ ${truncateToDecimals(buyingState.usdaoBuy ? calculationForUsdao.networkFeeBuy : calculationForUsdao.networkFeeSell, 3)}</div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <p className='text-left'><span className='cursor-pointer metamaskadd' onClick={() => addCustomTokenMetamask('usm')}>Add USDAO to Metamask</span></p>
                                    {/* {`$ ${decimalPlaces(
                                buyingState.assetBuy
                                    ? Number(usdaoInput.eth) * Number(prices.marketPrice)
                                    : Number(dollarState)
                            )}`} */}

                                </div>
                                <div className='button-content '>
                                    <button
                                        className='linear-button pointer buy-button-new'
                                        onClick={usdaoBuySell}
                                    >
                                        {buyingState.usdaoBuy ? 'BUY' : 'SELL'}
                                    </button>
                                </div>
                            </div>
                        </div>}
                    {/* Asset card buy /sell part */}
                    {activeTab === "asset" &&
                        <div className='col-md-12 justify-content-center align-items-center'>
                            <div className='calculation-card text-center px-4'>
                                <div className='row text-center'>
                                    <div className='col'>
                                        <p>ASSET</p>
                                    </div>
                                </div>
                                <div className='buy-sell-part row my-4'>
                                    <div
                                        className={
                                            buyingState.assetBuy
                                                ? 'col-6 text-center buy-sell-orange pointer'
                                                : 'col-6 text-center buy-sell-white pointer'
                                        }
                                        onClick={() => {
                                            setAssetInput({ assetBuyVal: '', ethBuyVal: '' })
                                            changeBuyingState('assetBuy')
                                            setCalculationForAsset(initialCalculatedValForAsset)
                                        }}
                                    >
                                        BUY
                                    </div>
                                    <div
                                        className={
                                            buyingState.assetBuy
                                                ? 'col-6 text-center buy-sell-white pointer'
                                                : 'col-6 text-center buy-sell-orange pointer'
                                        }
                                        onClick={() => {
                                            setAssetInput({ assetSellVal: '', ethSellVal: '' })
                                            changeBuyingState('assetBuy', false)
                                            setCalculationForAsset(initialCalculatedValForAsset)
                                            // setCalculationUsdao(initialCalculatedValForUsdao)
                                        }}
                                    >
                                        SELL
                                    </div>
                                </div>
                                <div className='input-content '>
                                    <input
                                        title=""
                                        type='number'
                                        placeholder='0.0000'
                                        className={`enable ${buyingState.assetBuy ? 'buy-asset' : 'sell'}`}
                                        onChange={(e) => setAssetInputNum({ value: e.target.value, isAsset: assetInput.ethBuyVal })}

                                        value={
                                            buyingState.assetBuy ? assetInput.ethBuyVal : assetInput.assetSellVal
                                        }
                                    />
                                    <button
                                        className='linear-button-1 pointer max-btn'
                                        onClick={assestMaxHandler}
                                    >
                                        MAX
                                    </button>
                                    <button className='linear-button-orange pointer'>
                                        {buyingState.assetBuy ? 'ETH' : 'ASSET'}
                                    </button>
                                </div>
                                <div className='input-content '>
                                    <input
                                        placeholder='0.0000'
                                        value={
                                            !buyingState.assetBuy ? assetInput.ethSellVal : assetInput.assetBuyVal
                                        }
                                        disabled
                                    />

                                    <button className='linear-button-orange pointer'>
                                        {!buyingState.assetBuy ? 'ETH' : 'ASSET'}
                                    </button>
                                </div>
                                <div className='transaction-details'>

                                    <div className='trasaction-bar accordion' onClick={onClickAccordian} >
                                        <PopOver content={contentForAsset(calculationForAsset, 'asset', buyingState)} />
                                        1 ASSET = {oneAssetVal ? truncateToDecimals(oneAssetVal, 6) : '0.00'}  ETH

                                    </div>
                                    <div className='accordion-content aa'>
                                        <div className="card" >


                                            <div className="card-body">
                                                <div className='d-flex card-content'>
                                                    <div>Expected Output</div>
                                                    <div >{truncateToDecimals(buyingState.assetBuy ? calculationForAsset.expectOutputBuy : calculationForAsset.expectOutputSell, 6)} ASSET</div>
                                                </div>
                                                {/* <div className='d-flex card-content'>
                                            <div>Price Impact</div>
                                            <div >-0.06%</div>
                                        </div> */}
                                                <hr className='seperate-line'></hr>
                                                <div className='d-flex card-content'>
                                                    <div>Slippage </div>
                                                    <div >{truncateToDecimals(buyingState.assetBuy ? calculationForAsset.slippageBuy : calculationForAsset.slippageSell, 6)} %</div>
                                                </div>
                                                <div className='d-flex card-content'>
                                                    <div>Network Fee</div>
                                                    <div >≈ ${truncateToDecimals(buyingState.assetBuy ? calculationForAsset.networkFeeBuy : calculationForAsset.networkFeeSell, 3)}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <p className='text-left'><span className='cursor-pointer metamaskadd' onClick={() => addCustomTokenMetamask('fum')}>Add ASSET to Metamask</span></p>

                                </div>
                                {/* <div className='text-right'>
                            {`$ ${decimalPlaces(
                                Number(assetInput.eth) * Number(prices.marketPrice)
                            )}`}
                        </div> */}

                                <div className='button-content '>
                                    <button
                                        className='linear-button pointer buy-button-new'
                                        onClick={assestBuySell}
                                    >
                                        {buyingState.assetBuy ? 'BUY' : 'SELL'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}

export default RightSideCard;