import React, {useEffect, useState} from 'react';

import {PopoverComp} from '../../util/utilComponent';
import {getVotes, setDelegate} from './helper';

const Delegates = ({votes, setVotes}) => {
    const [delegateTo, setDelegateTo] = useState(''),
    [disabledDeligate, setDisabledDeligate] = useState(false);

    useEffect(()=>{
        (async()=>{
            const votesDetails = await getVotes();
            setVotes(votesDetails);
        })()
       
    },[])

    return (
        <div className='row flex-sm-row mt-5 mb-5'>
					<div className='col-12'>
						<div className='shadow-card p-5'>
							<div className='row flex-sm-row '>
								<div className='col-md-8 pr-5'>
									<div className=' d-flex justify-content-start align-items-center'>
										<p className='medium-white-font mr-2'> DELEGATE</p>
										<PopoverComp
											content={
												'Delegate your votes to your self or any third party.'
											}
										/>
									</div>
									<div className='input-for-gov pt-3'>
										<p className='label-content my-3'>
											Provide address you want to delegate your votes
										</p>
										<input
											type='text'
											className='input-content mt-3'
											style={{ width: '100%' }}
											placeholder='Provide address you want to delegate your votes'
											onChange={(e) => setDelegateTo(e.target.value)}
											value={delegateTo}
										/>
									</div>
									<button
										className={
											disabledDeligate
												? 'disabled my-3 linear-button'
												: 'my-3 linear-button'
										}
										onClick={() => setDelegate(delegateTo, setDisabledDeligate, setDelegateTo, setVotes)}
									>
										Submit Now
									</button>
								</div>
								<div className='col-md-4'>
									<div className='row flex-sm-row d-flex align-items-center'>
										<div className='col-md-12'>
											<div className='d-flex align-items-center py-md-3'>
											<p className='caption-content'>Your Votes: {votes.yourVote}</p>

												<PopoverComp
													content={
														'The amount of DAOGOV tokens available in this account.'
													}
												/>
											</div>
										</div>
										<div className='col-md-12'>
											<div className='d-flex align-items-center py-md-3'>
											<p className='caption-content'>
													Delegated Votes: {votes.delegatedVotes}
												</p>
												<PopoverComp
													content={
														'Tokens that are delegated to address by other accounts.'
													}
												/>
												
											</div>
										</div>
										<div className='col-md-12'>
											<div className='d-flex align-items-center py-md-3'>
												
												<p className='caption-content'>
													Total Voting Power:{' '}
													{votes.delegatedVotes ? ((votes.delegatedVotes / (votes.divider / 10e17)) * 100).toFixed(4) : '0'}{' '}
													%
												</p>
												<PopoverComp
													content={'The total voting power you possess.'}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
    )
}

export default Delegates;