import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers'
import { NavBar } from '../pages/common/Header';
import { readContractFunction, writeContractFunction } from '../blockchain/tradingSdk';

const GovernanceAdmin = () =>{
    const [data, setData] = useState({}),
        [revenueFun, setRevenueFun] = useState(),
        [usmFun, setUsmFun] = useState(),
        onChange = (e) =>{
            const {name, value} = e.target;
          setData({...data, [name]:value});
        },
        withdrawEth = async() =>{
          
          const weiAmount = ethers.utils.parseEther(data.withdrawEthValue);
          const res = await revenueFun.etherReserveTransfer(data.withdrawEthAddress, weiAmount);
        },
        withdrawUsdao = async() =>{
            const weiAmount = ethers.utils.parseEther(data.withdrawUSDAOValue);

            const res = await revenueFun.usdaoReserveTransfer(data.withdrawUSDAOAddress, weiAmount);
          },
          changeUsdaoMint = async() =>{

            const res = await usmFun.changeMintFee(data.usdaoMint1, data.usdaoMint2);
          },
          changeUsdaoTransfer = async() =>{

            const res = await usmFun.changeTransferFee(data.usdaoTransfer1, data.usdaoTransfer2);
          },
          withdrawAsset = async() =>{
            const weiAmount = ethers.utils.parseEther(data.withdrawAssetValue);

            const res = await revenueFun.assetReserveTransfer(data.withdrawAssetAddress, weiAmount);
          },
          changeFoundationWithdrawLimit = () =>{
            const res = revenueFun.changeFoundationWidthdrawalLimt(data.limit1,data.limit2)
          },
          changeUSDAOBurnFee = () =>{
            const res = usmFun.changeBurnFee(data.burn1,data.burn2)

          },
        foundationRevenueWithdraw = async()=>{
            const res = await revenueFun.foundationRevenueWithdraw();
        }
    ;
    useEffect(()=>{
        (async()=>{
            const revenueContract = await writeContractFunction('revenue');
            setRevenueFun(revenueContract);
            setUsmFun(await writeContractFunction('usm'))
            window.usmContract = revenueContract;
            console.log("revenueContract", revenueContract);

            const ethBal = ethers.utils.formatEther(await revenueContract.getEthBalance());
            const assetBal = ethers.utils.formatEther(await revenueContract.getAssetReserve("0x0307dc88912BDAaB633A032019981b750Aa8828F"));
            const usdaoBal = ethers.utils.formatEther(await revenueContract.getUSDAOReserve());
            //setData({...data,ethBal:Number(ethBal),assetBal,usdaoBal});
        })()
       

    }, [])

    console.log("data", data)
    return(
        <>
        <NavBar />
        <div className='row governanceWrapper'>
            <div className='col-md-12'>
                <div className='text-center left-text'>
                    <h2>Governance </h2>
                    <h2>Through SnapShot</h2>
                </div>
            </div>
            <div className='row gov-form-wrapper'>
            <div className='col-md-6'>
                <div className=''>
                    <label>Withdraw ETH</label>
                    <input placeholder="Type address"  onChange= {onChange} name="withdrawEthAddress"/>
                    <input placeholder='Type eth value'  onChange= {onChange} name="withdrawEthValue"/>
                    <div className='buttonWrapper'>
                        <button onClick={withdrawEth}>Submit</button>
                    </div>

                    <label className='m-t-50'>Withdraw USDAO</label>
                    <input placeholder="Type address"  onChange= {onChange} name="withdrawUSDAOAddress" />
                    <input placeholder='USDAO value'  onChange= {onChange} name="withdrawUSDAOValue" />
                    <div className='buttonWrapper'>
                        <button onClick={withdrawUsdao}>Submit</button>
                    </div>

                    <label className='m-t-50'>Change USDAO mint fee</label>
                    <input placeholder="Type here mint fee" onChange= {onChange} name="usdaoMint1" />
                    <input placeholder='Type here mint fee' onChange= {onChange} name="usdaoMint2" />
                    <div className='buttonWrapper'>
                        <button onClick={changeUsdaoMint}>Submit</button>
                    </div>

                    <label className='m-t-50'>Change USDAO Transfer</label>
                    <input placeholder="Type here transfer fee" onChange= {onChange} name="usdaoTransfer1" />
                    <input placeholder='Type here transfer fee' onChange= {onChange} name="usdaoTransfer2" />
                    <div className='buttonWrapper'>
                        <button onClick={changeUsdaoTransfer}>Submit</button>
                    </div>
                    
                </div>
            </div>
            <div className='col-md-6'>
                <div className=''>
                <label>Withdraw ASSET</label>
                <input placeholder="Type address"  onChange= {onChange} name="withdrawAssetAddress" />
                <input placeholder='USDAO value'  onChange= {onChange} name="withdrawAssetValue" />
                    
                    <div className='buttonWrapper'>
                        <button onClick={withdrawAsset}>Submit</button>
                    </div>

                <label className='m-t-50'>Change Foundation Withdraw Limit</label>
                <input placeholder="Type here" onChange= {onChange} name="limit1" />
                <input placeholder='Type here' onChange= {onChange} name="limit2" />
                <div className='buttonWrapper'>
                    <button onClick={changeFoundationWithdrawLimit}>Submit</button>
                </div>    

                <label className='m-t-50'>Change USDAO Burn Fee</label>
                <input placeholder="Type here" onChange= {onChange} name="burn1" />
                <input placeholder='Type here' onChange= {onChange} name="burn2" />
                <div className='buttonWrapper'>
                    <button onClick={changeUSDAOBurnFee}>Submit</button>
                </div> 

                <label className='m-t-50 text-center'>Foundation Withdraw</label>
                <div className='buttonWrapper text-center'>
                    <button onClick={foundationRevenueWithdraw}>Submit</button>
                </div>     
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default GovernanceAdmin;