import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { NavBar } from "../pages/common/Header";
import {
  readContractFunction,
  writeContractFunction,
} from "../blockchain/tradingSdk";
import LeftNav from "./leftnav";

const Fee = () => {
  const [data, setData] = useState({}),
    [revenueFun, setRevenueFun] = useState(),
    [usmFun, setUsmFun] = useState(),
    [contractData, setContractData] = useState({}),
    getUsdaoBurnFee = async () => {
      const usdaoFun = await writeContractFunction("usm");
      const vaultFun = await writeContractFunction("vaultManager");

      
      console.log("usmFun", usdaoFun);

      const USDAOBurnFee = await usdaoFun.USDAOBurnFee();
      const USDAOBurnFeePercent = ethers.utils.formatEther(USDAOBurnFee) * 100;

      const USDAOTransferFee = await usdaoFun.USDAOTransferFee();
      const USDAOTransferFeePercent =
        ethers.utils.formatEther(USDAOTransferFee) * 100;

        const USDAOMintFee = await usdaoFun.USDAOMintFee();
      const USDAOMintFeePercent =
        ethers.utils.formatEther(USDAOMintFee) * 100;

        
        const borrowFee = await vaultFun.getBorrowingRate();
      const borrowFeePercent =
        ethers.utils.formatEther(borrowFee) * 100;

        const foundationAddress =  await usdaoFun.foundationAddress();

        

      setContractData({ USDAOBurnFeePercent, USDAOTransferFeePercent, USDAOMintFeePercent, borrowFeePercent, foundationAddress });
    },
    onChange = (e) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    changeUSDAOFoundationAdd = async()=>{
      const res = await usmFun.changeFoundationAddress( data.foundationAddress);
    },
    withdrawEth = async () => {
      const weiAmount = ethers.utils.parseEther(data.withdrawEthValue);
      const res = await revenueFun.etherReserveTransfer(
        data.withdrawEthAddress,
        weiAmount
      );
    },
    withdrawUsdao = async () => {
      const weiAmount = ethers.utils.parseEther(data.withdrawUSDAOValue);

      const res = await revenueFun.usdaoReserveTransfer(
        data.withdrawUSDAOAddress,
        weiAmount
      );
    },
    changeUsdaoMint = async () => {
      const res = await usmFun.changeMintFee(data.usdaoMint1, data.usdaoMint2);
    },
    changeUsdaoTransfer = async () => {
      const res = await usmFun.changeTransferFee(
        data.usdaoTransfer1,
        data.usdaoTransfer2
      );
    },
    withdrawAsset = async () => {
      const weiAmount = ethers.utils.parseEther(data.withdrawAssetValue);

      const res = await revenueFun.assetReserveTransfer(
        data.withdrawAssetAddress,
        weiAmount
      );
    },
    changeFoundationWithdrawLimit = () => {
      const res = revenueFun.changeFoundationWidthdrawalLimt(
        data.limit1,
        data.limit2
      );
    },
    changeUSDAOBurnFee = () => {
      const res = usmFun.changeBurnFee(data.burn1, data.burn2);
    },
    foundationRevenueWithdraw = async () => {
      const res = await revenueFun.foundationRevenueWithdraw();
    };
  useEffect(() => {
    (async () => {
      const revenueContract = await writeContractFunction("revenue");
      setRevenueFun(revenueContract);
      setUsmFun(await writeContractFunction("usm"));
      window.usmContract = revenueContract;
      // console.log("revenueContract", revenueContract);

      // const ethBal = ethers.utils.formatEther(await revenueContract.getEthBalance());
      // const assetBal = ethers.utils.formatEther(await revenueContract.getAssetReserve("0x0307dc88912BDAaB633A032019981b750Aa8828F"));
      // const usdaoBal = ethers.utils.formatEther(await revenueContract.getUSDAOReserve());
      getUsdaoBurnFee();
      //setData({...data,ethBal:Number(ethBal),assetBal,usdaoBal});
    })();
  }, []);

  console.log("contractData", contractData);
  return (
    <>
      <NavBar />
      <LeftNav />
      <div className="container">
        <div className="row governanceWrapper">
          <div className="col-md-12">
            <div className="text-center left-text">
              <h2>fee</h2>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
                Change USDAO Burn Fee:
                <h2 className="text-center text-white mt-2"> {contractData.USDAOBurnFeePercent}</h2>
              </label>
            </div>
          </div>

          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
                 USDAO transfer Fee:
                <h2 className="text-center text-white mt-2"> {contractData.USDAOTransferFeePercent}</h2>
              </label>
            </div>
          </div>

          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
                 USDAO Mint Fee:
                <h2 className="text-center text-white mt-2"> {contractData.USDAOMintFeePercent}</h2>
              </label>
            </div>
          </div>

          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
              USDAO staking Fee:
                <h2 className="text-center text-white mt-2"> 0.1%</h2>
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
              Onvault vault creation fee or borrowing fee:
                <h2 className="text-center text-white mt-2">{contractData.borrowFeePercent}</h2>
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
             Foundation Address:
                <h6 className="text-center text-white mt-2" style={{fontSize:'11px'}}>{contractData.foundationAddress}</h6>
              </label>
            </div>
          </div>

          <div className="col-md-6">
            <div className="admin-card">
              <label className="m-t-50">
                 Change USDAO Foundation Address:
                 <input placeholder="Enter Foundation Address" onChange= {onChange} name="foundationAddress" />
                 <div className='buttonWrapper'>
                    <button onClick={changeUSDAOFoundationAdd}>Submit</button>
                </div> 
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="admin-card">
            <label className='m-t-50'>Change USDAO mint fee</label>
                    <input placeholder="Type here mint fee" onChange= {onChange} name="usdaoMint1" />
                    <input placeholder='Type here mint fee' onChange= {onChange} name="usdaoMint2" />
                    <div className='buttonWrapper'>
                        <button onClick={changeUsdaoMint}>Submit</button>
                    </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="admin-card">
            <label className='m-t-50'>Change USDAO Burn Fee</label>
                <input placeholder="Type here" onChange= {onChange} name="burn1" />
                <input placeholder='Type here' onChange= {onChange} name="burn2" />
                <div className='buttonWrapper'>
                    <button onClick={changeUSDAOBurnFee}>Submit</button>
                </div> 
            </div>
          </div>
          

          <div className="col-md-6">
            <div className="admin-card">
            <label className='m-t-50'>Change USDAO Transfer N/A</label>
                    <input placeholder="Type here transfer fee" onChange= {onChange} name="usdaoTransfer1" />
                    <input placeholder='Type here transfer fee' onChange= {onChange} name="usdaoTransfer2" />
                    <div className='buttonWrapper'>
                        <button onClick={changeUsdaoTransfer} disabled>Submit</button>
                    </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Fee;
