import axios from 'axios'

export const truncateToDecimals = (num, dec = 2) => {
	const calcDec = Math.pow(10, dec)
	return String(Math.trunc(num * calcDec) / calcDec)
}


export const decimalPlaces = (numberString, decimals = 2) => {
	const value = Number(truncateToDecimals(numberString))

	if (isNaN(value)) {
		return ''
	} else {
		return value.toLocaleString(undefined, {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals
		})
	}
}

export const getCoingeckoPrice = async (dispatch) => {
	// const response = await axios.get(
	// 	'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
	// )

	const response = await axios.get(
		'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd',
		{
		  // query URL without using browser cache
		  headers: {
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache',
			'Expires': '0',
		  },
		}
	  )
	return response.data.ethereum.usd
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
  }

export const toPercentage = (decimalString) => {
	const num = Number.parseFloat(decimalString) * 100
	return decimalPlaces(num.toString())
}